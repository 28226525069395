import { Command } from "@geome/flux"

class SetDirectionsStep extends Command {
  execute(step) {
    this.dispatch({
      type: this.actionTypes.LAYOUT_DIRECTIONS_STEP_SET,
      payload: step,
    })
  }
}

export default SetDirectionsStep
