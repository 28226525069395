import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Locations from "./locations"
import Map from "@geome/react-components/lib/components/map"
import CustomRenderer from "@geome/react-components/lib/components/map/renderer/customRenderer"
import { MAP_OPTIONS_DESKTOP, MAP_OPTIONS_MOBILE } from "./mapOptions"
import TrafficButton from "../../trafficButton"
import { DIRECTIONS_STEPS } from "../../../values/directionsSteps"

const APP_WIDTH = 433

class MapView extends React.Component {
  static propTypes = {
    locations: PropTypes.arrayOf(PropTypes.object),
    selectedPlace: PropTypes.object,
    isMobile: PropTypes.bool,
    mapTarget: PropTypes.object,
    isShowingTraffic: PropTypes.bool,
    directions: PropTypes.object,
    showStationsOnRoute: PropTypes.bool,
    directionsStep: PropTypes.oneOf([null, DIRECTIONS_STEPS.INPUT, DIRECTIONS_STEPS.VIEWING_ROUTE]),
  }

  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.state = {}
  }

  componentDidMount() {
    window.requestAnimationFrame(() => this.measureContainer())
  }

  componentDidUpdate() {
    window.requestAnimationFrame(() => this.measureContainer())
  }

  getMapBoundingRect() {
    return this.containerRef?.current?.getBoundingClientRect()
  }

  measureContainer() {
    const { width, height } = this.state
    const mapRect = this.getMapBoundingRect()
    if (mapRect && (mapRect.height !== height || mapRect.width !== width)) {
      this.setState({
        height: mapRect.height,
        width: mapRect.width,
      })
    }
  }

  getNavigationOffset() {
    const { mapTarget, isMobile } = this.props
    if (isMobile) {
      if (mapTarget && !mapTarget.bounds) {
        return { x: 0, y: this.state.height / 2 - 50 }
      } else {
        return { x: 0, y: 0 }
      }
    } else {
      return { x: APP_WIDTH / 2, y: 0 }
    }
  }

  shouldRenderLocations() {
    const { showStationsOnRoute, isMobile, directionsStep } = this.props
    if (directionsStep === null) {
      return true
    } else if (!showStationsOnRoute) {
      return false
    } else if (isMobile && directionsStep === DIRECTIONS_STEPS.INPUT) {
      return false
    }
    return true
  }

  shouldRenderRouteLine() {
    const { isMobile, directionsStep } = this.props
    if (isMobile && directionsStep === DIRECTIONS_STEPS.INPUT) {
      return false
    }
    return true
  }

  render() {
    const { isMobile, selectedPlace, isShowingTraffic, directions } = this.props

    return (
      <div className="map__container" ref={this.containerRef}>
        <Map
          {...this.props}
          mapOptions={isMobile ? MAP_OPTIONS_MOBILE : MAP_OPTIONS_DESKTOP}
          navigationOffset={this.getNavigationOffset()}
        >
          {({ handleOverlayRequest, handleRendererRequest }) => (
            <Fragment>
              <Locations
                {...this.props}
                onOverlayRequest={handleOverlayRequest}
                selectedPlace={selectedPlace}
                shouldRender={this.shouldRenderLocations()}
              />
              <CustomRenderer
                renderer={handleRendererRequest("traffic", "default")}
                shouldRender={isShowingTraffic}
              />
              <CustomRenderer
                renderer={handleRendererRequest("directions", "gapi")}
                subject={directions}
                shouldRender={Boolean(directions) && this.shouldRenderRouteLine()}
              />
              <TrafficButton isMobile={isMobile} />
            </Fragment>
          )}
        </Map>
      </div>
    )
  }
}

export default MapView
