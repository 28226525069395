const getFilteredProperties = (enabled, properties) => {
  if (enabled == null || properties == null) return properties
  return enabled.filter(p => properties.includes(p))
}

const getLocationWithFilteredProperties = (locationInfo, location) => {
  if (!locationInfo) return location
  const { facilities, products } = location
  return {
    ...location,
    facilities: getFilteredProperties(locationInfo.facilities, location.facilities),
    products: getFilteredProperties(locationInfo.products, location.products)
  }
}

export { getLocationWithFilteredProperties }
