import React from "react"
import PropTypes from "prop-types"

const CloseIcon = ({ className }) => {
  return (
    <svg
      className={className || ""}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        fill="#333"
        fillRule="nonzero"
        d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"
      />
    </svg>
  )
}
CloseIcon.propTypes = {
  className: PropTypes.string
}
export default CloseIcon
