import Command from "@geome/flux/lib/command"

class ClearSaveRouteStatus extends Command {
  execute() {
    this.dispatch({
      type: this.actionTypes.DIRECTIONS_SAVE_ROUTE_STATUS_SET,
      payload: false,
    })
  }
}

export default ClearSaveRouteStatus
