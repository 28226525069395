import { Command } from "@geome/flux"

class RequestRoutesDirections extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute() {
    this.httpClient.get("/routes").then(({ responseJSON }) => {
      this.dispatch({
        type: this.actionTypes.DIRECTIONS_ROUTES_LOADED,
        payload: responseJSON,
      })
    })
  }
}

export default RequestRoutesDirections
