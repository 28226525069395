import React from "react"
import AnalyticsContainer from "@geome/react-components/lib/containers/analytics"
import PropTypes from "prop-types"
import { Router } from "react-router"
import AppContainer from "@geome/react-next/lib/appContainer"
import RoutingContainer from "@geome/react-components/lib/containers/routing"
import Resources from "./resources"
import Map from "./map"
import LocationsList from "./locationsList"
import SvgDefs from "./svgDefs"
import AppLayout from "./appLayout"
import InfoWindow from "./infoWindow"
import Itinerary from "./itinerary"

class App extends React.Component {
  static propTypes = {
    routerProps: PropTypes.object,
    routingHistory: PropTypes.object,
    routingRegistry: PropTypes.object,
    mapFactory: PropTypes.object,
  }

  renderContent(activeWindow) {
    switch (activeWindow) {
      case "locationsList":
        return <LocationsList />
      case "infoWindow":
        return <InfoWindow />
      case "itinerary":
        return <Itinerary />
      default:
        return null
    }
  }

  render() {
    return (
      <AppContainer {...this.props}>
        <AnalyticsContainer />
        <RoutingContainer
          history={this.props.routingHistory}
          registry={this.props.routingRegistry}
        />
        <Resources />
        <AppLayout
          renderMap={() => <Map mapFactory={this.props.mapFactory} />}
          renderHeader={() => <Router {...this.props.routerProps} />}
          renderContent={({ activeWindow }) => this.renderContent(activeWindow)}
        />
        <SvgDefs />
      </AppContainer>
    )
  }
}

export default App
