import React from "react"
import withI18n from "@geome/react-next/lib/withI18n"
import PropTypes from "prop-types"
import CloseIcon from "../../../assets/icons/close"

class FiltersHeader extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
    isShowingFiltersSection: PropTypes.bool.isRequired,
    onToggleFiltersSection: PropTypes.func.isRequired,
    activeFiltersCount: PropTypes.number.isRequired,
    translate: PropTypes.func.isRequired,
  }

  renderCloseButton() {
    const { isShowingFiltersSection } = this.props
    if (!isShowingFiltersSection) return null
    return (
      <div className="filters__header-close-button">
        <CloseIcon />
      </div>
    )
  }

  renderLabel() {
    const { translate, activeFiltersCount } = this.props
    if (activeFiltersCount > 0) {
      return translate("filters.title_applied", { count: activeFiltersCount })
    }
    return translate("filters.title")
  }

  render() {
    return (
      <div className="filters__header" onClick={this.props.onToggleFiltersSection}>
        <div className="filters__header-label">{this.renderLabel()}</div>
        {!this.props.isMobile && this.renderCloseButton()}
      </div>
    )
  }
}

export { FiltersHeader as UnwrappedFiltersHeader }
export default withI18n(FiltersHeader)
