import { Command } from "@geome/flux"

class WatchlistToggle extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute(location) {
    const watchlistId = location.watchlist_id
    const locationIsWatched = this.queryHandler.get("watchlist.list").includes(watchlistId)

    this.dispatch({
      type: this.actionTypes.WATCHLIST_TOGGLED,
      payload: { watchlistId },
    })

    window.parent.postMessage(
      {
        event: "locationlistinteraction",
        ctaName: locationIsWatched
          ? "Aus „Meine Tankstellen“ entfernen"
          : "Zu „Meine Tankstellen“ hinzufügen",
        stationName: location.name,
        stationCity: location.city,
      },
      "*"
    )

    if (locationIsWatched) {
      this.removeFromApiWatchlist(watchlistId)
    } else {
      this.addToApiWatchlist(watchlistId)
    }
  }

  removeFromApiWatchlist(watchlistId) {
    window.parent.postMessage({ event: "remove-from-watchlist", id: watchlistId }, "*")
  }

  addToApiWatchlist(watchlistId) {
    window.parent.postMessage({ event: "add-to-watchlist", id: watchlistId }, "*")
  }
}

export default WatchlistToggle
