import React from "react"
import PropTypes from "prop-types"
import Marker from "@geome/react-components/lib/components/marker"
import MarkerIcon from "../../../../assets/icons/marker"

const AralMarker = (props) => {
  const { subject } = props
  const isNonMatching = subject.resource === "non_matching_locations"

  return (
    <Marker {...props} interactionDisabled={isNonMatching} title={props.subject.name}>
      <MarkerIcon {...props.subject} isNonMatching={isNonMatching} />
    </Marker>
  )
}

AralMarker.propTypes = {
  subject: PropTypes.object,
  className: PropTypes.string,
}

export default AralMarker
