import React from "react"
import ResourcesContainer from "@geome/react-components/lib/containers/resources"
import connectToFlux from "@geome/react-next/lib/connectToFlux"

const getReferencePoint = (props) => {
  return props.selectedPlace?.origin || props.waypoints?.[0]?.place?.origin || props.mapCenter
}

const changeConstraints = (constraints, props) => {
  return {
    ...constraints,
    ...props.directionsOptions,
    origin: getReferencePoint(props),
    key: props.gApiSettingsKey,
    selected: props.selectedLocation,
  }
}

const changeMetadata = (metadata, props) => {
  return {
    ...metadata,
    referencePoint: getReferencePoint(props),
  }
}

const Resources = (props) => {
  return (
    <ResourcesContainer
      {...props}
      changeConstraints={(constraints) => changeConstraints(constraints, props)}
      changeMetadata={(metadata) => changeMetadata(metadata, props)}
    />
  )
}

const queries = {
  bounds: "map.geometry.bounds",
  withAll: "filters.constraints.withAll",
  radius: "resources.constraints.radius",
  selectedPlace: "search.selectedPlace",
  mapCenter: "map.geometry.offsetCenter",
  line: "directions.routeLine",
  waypoints: "directions.waypoints",
  directionsOptions: "directions.options",
  selectedLocation: "locations.selected",
}

export default connectToFlux(queries, {})(Resources)
