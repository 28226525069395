import React from "react"
import FiltersView from "./view"
import PropTypes from "prop-types"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import FiltersContainer from "@geome/react-components/lib/containers/filters"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"

class Filters extends React.Component {
  static propTypes = {
    isShowingFiltersSection: PropTypes.bool,
    filterGroups: PropTypes.array,
    renderAsModal: PropTypes.bool,
    layoutToggleWindow: PropTypes.func,
    activeFiltersCount: PropTypes.number,
  }

  componentWillUnmount() {
    const { isShowingFiltersSection, layoutToggleWindow } = this.props
    if (isShowingFiltersSection) layoutToggleWindow("filters")
  }

  render() {
    const { layoutToggleWindow, ...props } = this.props
    return (
      <FiltersContainer>
        {(viewProps) => (
          <FiltersView
            {...viewProps}
            {...props}
            onToggleFiltersSection={() => layoutToggleWindow("filters")}
          />
        )}
      </FiltersContainer>
    )
  }
}

const queries = {
  isShowingFiltersSection: "layout.window.filters.active",
  activeFiltersCount: "filters.activeCount",
}

const commands = {
  layoutToggleWindow: "layout.toggleWindow",
}

export { Filters as UnwrappedFilters }

export default withContexts(EnvironmentContext)(connectToFlux(queries, commands)(Filters))
