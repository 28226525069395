import React from "react"
import PropTypes from "prop-types"
import Filters from "../../filters"
import withI18n from "@geome/react-next/lib/withI18n"
import Route from "./route"
import { DIRECTIONS_STEPS } from "../../../values/directionsSteps"
import ViewRoutesButton from "../../savedRoutes/viewRoutesButton"

class DirectionsView extends React.Component {
  static propTypes = {
    onGeolocationSelect: PropTypes.func,
    onWaypointsRemove: PropTypes.func,
    onExitDirections: PropTypes.func,
    onWaypointsAdd: PropTypes.func,
    onSwitchWaypoints: PropTypes.func,
    onToggleRouteSettings: PropTypes.func,
    onCompletionRequest: PropTypes.func,
    onCompletionSelect: PropTypes.func,
    onIncompleteSearchSubmission: PropTypes.func,
    onChangeDirectionsOptions: PropTypes.func,
    onWaypointAdd: PropTypes.func,
    onWaypointChange: PropTypes.func,
    onWaypointClear: PropTypes.func,
    onWaypointRemove: PropTypes.func,
    setDirectionsStep: PropTypes.func,
    onNewRoute: PropTypes.func,
    translate: PropTypes.func,
    geolocationStatus: PropTypes.string,
    isGeolocationAvailable: PropTypes.bool,
    isShowingRouteOptions: PropTypes.bool,
    waypointsLimitReached: PropTypes.bool,
    isMobile: PropTypes.bool,
    directionsStep: PropTypes.oneOf([null, DIRECTIONS_STEPS.INPUT, DIRECTIONS_STEPS.VIEWING_ROUTE]),
    completions: PropTypes.object,
    queries: PropTypes.array,
    waypoints: PropTypes.array,
    directions: PropTypes.object,
    directionsOptions: PropTypes.object,
  }

  static defaultProps = {
    isMobile: false,
    isShowingRouteOptions: false,
  }

  renderMobileFindRouteButton() {
    const { directionsStep, setDirectionsStep, translate } = this.props
    if (directionsStep === DIRECTIONS_STEPS.VIEWING_ROUTE) return null
    return (
      <div className="directions__container-find-route">
        <button
          className="directions__container-find-route-button"
          onClick={() => setDirectionsStep(DIRECTIONS_STEPS.VIEWING_ROUTE)}
        >
          {translate("directions.route.find")}
        </button>
      </div>
    )
  }

  renderMobileEditAndNewButtons() {
    return (
      <div className="directions__route-view" ref={(p) => (this.panel = p)}>
        <button
          className="directions__route-view-edit-button"
          onClick={() => this.props.setDirectionsStep(DIRECTIONS_STEPS.INPUT)}
        >
          {this.props.translate("directions.route.edit")}
        </button>
        <button
          className="directions__route-view-new-button"
          onClick={() => this.props.onNewRoute()}
        >
          {this.props.translate("directions.route.new")}
        </button>
      </div>
    )
  }

  render() {
    const { directionsStep, isMobile } = this.props
    if (directionsStep === DIRECTIONS_STEPS.VIEWING_ROUTE)
      return this.renderMobileEditAndNewButtons()

    return (
      <div className="directions__container" ref={(p) => (this.panel = p)}>
        <Route {...this.props} />
        <Filters renderAsModal />
        <ViewRoutesButton />
        {isMobile && this.renderMobileFindRouteButton()}
      </div>
    )
  }
}

export { DirectionsView as UnwrappedDirectionsView }
export default withI18n(DirectionsView)
