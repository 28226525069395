import React from "react"
import PropTypes from "prop-types"

const SearchIcon = ({ className }) => {
  return (
    <svg className={className || ""} xmlns="http://www.w3.org/2000/svg" width="21" height="21">
      <path
        fill="#0064CC"
        d="M8.002 16.004C3.59 16.004 0 12.414 0 8.002 0 3.589 3.59 0 8.002 0c4.412 0 8.002 3.59 8.002 8.002 0 4.412-3.59 8.002-8.002 8.002zm0-13.271a5.275 5.275 0 0 0-5.27 5.269 5.276 5.276 0 0 0 5.27 5.27 5.277 5.277 0 0 0 5.27-5.27 5.276 5.276 0 0 0-5.27-5.27zm8.105 10.887a9.946 9.946 0 0 1-2.665 2.609L18.212 21l2.637-2.636-4.742-4.744z"
      />
    </svg>
  )
}
SearchIcon.propTypes = {
  className: PropTypes.string
}
export default SearchIcon
