import connectToFlux from "@geome/react-next/lib/connectToFlux"
import withI18n from "@geome/react-next/lib/withI18n"
import React from "react"
import PropTypes from "prop-types"

const TrafficButton = ({ isShowingTraffic, onClick, translate, isMobile }) => {
  const shownOrHidden = isShowingTraffic ? "shown" : "hidden"
  if (isMobile) return null
  return (
    <button onClick={onClick} className={`traffic-button traffic-button--${shownOrHidden}`}>
      {translate(`traffic_button.${shownOrHidden}`)}
    </button>
  )
}

TrafficButton.propTypes = {
  isShowingTraffic: PropTypes.bool,
  translate: PropTypes.func,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
}

const queries = {
  isShowingTraffic: "map.isShowingTraffic",
}

const commands = {
  onClick: "map.toggleTrafficLayer",
}

export { TrafficButton as UnwrappedTrafficButton }
export default withI18n(connectToFlux(queries, commands)(TrafficButton))
