import React from "react"
import PropTypes from "prop-types"

class AppLayoutView extends React.Component {
  static propTypes = {
    renderMap: PropTypes.func.isRequired,
    renderHeader: PropTypes.func.isRequired,
    renderContent: PropTypes.func.isRequired,
    activeWindow: PropTypes.string,
    isMobile: PropTypes.bool,
    viewportHeight: PropTypes.number,
    bottomContentOverlap: PropTypes.number,
    isShowingFiltersSection: PropTypes.bool
  }

  static defaultProps = {
    bottomContentOverlap: 262
  }

  state = {
    isScrolling: false,
    topContentBottom: 0
  }

  componentDidMount() {
    this.setSizes()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeWindow !== this.props.activeWindow) {
      window.requestAnimationFrame(() => (this.bottomContentContainer.scrollTop = 0))
    }
    this.setSizes()
  }

  onBottomContentScroll(e) {
    const { isScrolling } = this.state
    if (!isScrolling) {
      this.setState({ isScrolling: true })
    } else if (this.bottomContentContainer.scrollTop === 0) {
      this.setState({ isScrolling: false })
    }
  }

  setSizes() {
    const topContentBottom = this.topContentContainer.getBoundingClientRect().bottom
    if (topContentBottom !== this.state.topContentBottom) {
      this.setState({
        topContentBottom
      })
    }
  }

  getBottomContentProps() {
    const { bottomContentOverlap, viewportHeight, isMobile } = this.props
    const { topContentBottom, isScrolling } = this.state
    const bottomContentTop = viewportHeight - bottomContentOverlap
    const style = isScrolling
      ? { paddingTop: `${bottomContentTop - topContentBottom}px` }
      : { top: `${bottomContentTop}px`, height: `${bottomContentOverlap}px` }

    return isMobile
      ? {
          className: `app-layout__bottom-content ${
            isScrolling ? "is-scrollable" : "is-not-scrollable"
          }`,
          style: {
            ...style,
            ...(isScrolling && { height: `calc(100vh - ${topContentBottom}px)` })
          },
          onScroll: e => this.onBottomContentScroll(e)
        }
      : {}
  }

  render() {
    const {
      activeWindow,
      renderMap,
      renderHeader,
      renderContent,
      isShowingFiltersSection
    } = this.props
    const { isScrolling } = this.state

    return (
      <div className="app-layout">
        <div className={`app-layout__map-container ${isScrolling ? "is-blocked" : ""}`}>
          {renderMap()}
        </div>
        <div className="app-layout__top-content" ref={el => (this.topContentContainer = el)}>
          {renderHeader()}
        </div>
        <div
          className={`app-layout__bottom-content ${isShowingFiltersSection ? "is-disabled" : ""}`}
          {...this.getBottomContentProps()}
          ref={el => (this.bottomContentContainer = el)}
        >
          {renderContent({ activeWindow })}
        </div>
      </div>
    )
  }
}

export default AppLayoutView
