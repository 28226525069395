import RegionalTargets from "@geome/intl/lib/regionalTargets"
import DirectionsOptions from "./directions"
import Filters from "./filters"
import IntlData from "./intlData"
import LocationInfo from "./locationInfo"

export default {
  intlData: IntlData,
  locationInfo: LocationInfo,
  locale: "de_DE",
  filters: Filters,
  remote: `api/v1/auth${window.location.search}`,
  authFailureRedirect: "http://aral.de",
  baseUrl: "/api/v1",
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY,
  },
  waypointsLimit: 7,
  directions: {
    options: DirectionsOptions,
  },
  resources: {
    locations: {
      enabled: true,
    },
    non_matching_locations: {
      enabled: true,
    },
  },
  map: {
    settings: {
      zoom: 6,
      center: {
        lat: 55,
        lng: -4,
      },
    },
    regionalTargets: RegionalTargets,
  },
}
