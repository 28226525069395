import { Command } from "@geome/flux"

class SaveDirections extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute(name) {
    const options = this.query("directions.options")
    const waypoints = this.query("directions.waypoints")
      .map((w) => w.place)
      .map(({ origin, description }) => ({ origin, description }))
    this.httpClient
      .post("/routes", { name, route: { waypoints, options } })
      .then(() =>
        this.dispatch({
          type: this.actionTypes.DIRECTIONS_SAVE_ROUTE_STATUS_SET,
          payload: true,
        })
      )
      .catch(({ xhr }) => {
        this.dispatch({
          type: this.actionTypes.DIRECTIONS_SAVE_ROUTE_STATUS_SET,
          payload: {},
          error: JSON.parse(xhr.response),
        })
      })
  }
}

export default SaveDirections
