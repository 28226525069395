import React from "react"
import PropTypes from "prop-types"
import LocationsListView from "@geome/react-components/lib/components/locationsList"
import LocationsListItem from "./item"
import withI18n from "@geome/react-next/lib/withI18n"
import { getLocationWithFilteredProperties } from "../../utils/locations"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import ChevronUpIcon from "../../assets/icons/chevronUp"

class LocationsList extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    translate: PropTypes.func,
    locations: PropTypes.array,
    locationInfo: PropTypes.object,
  }

  renderHeader() {
    const { isMobile, translate } = this.props
    return isMobile ? (
      <div className="locations-list__title--mobile">
        <span>{translate("locations_list.title")}</span>
        <ChevronUpIcon className="chevron-up" />
      </div>
    ) : (
      <span className="locations-list__title">{translate("locations_list.title")}</span>
    )
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        <LocationsListView
          {...this.props}
          locations={this.props.locations.map((l) =>
            getLocationWithFilteredProperties(this.props.locationInfo, l)
          )}
          renderLocation={({ props, item, isSelected, isHighlighted }) => (
            <LocationsListItem
              {...props}
              key={item.uniqueId}
              item={item}
              isSelected={isSelected}
              isHighlighted={isHighlighted}
              translate={this.props.translate}
            />
          )}
        />
      </div>
    )
  }
}

export { LocationsList as UnwrappedLocationsList }
export default withContexts(EnvironmentContext)(withI18n(LocationsList))
