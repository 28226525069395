import React from "react"
import PropTypes from "prop-types"

const DirectionsIcon = ({ className }) => {
  return (
    <svg
      className={className || ""}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0064CC"
        d="M14.019 14.565l4.047-4.048-4.047-4.102v2.898H8.167c-.657 0-1.148.548-1.148 1.203v4.648h2.297v-3.5h4.703v2.901zm8.968-3.719c.437.493.437 1.203 0 1.64l-10.5 10.5c-.438.438-1.203.438-1.64 0l-10.5-10.5c-.438-.437-.438-1.202 0-1.64l10.5-10.5c.437-.437 1.202-.437 1.64 0l10.5 10.5z"
      />
    </svg>
  )
}
DirectionsIcon.propTypes = {
  className: PropTypes.string
}
export default DirectionsIcon
