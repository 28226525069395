export default {
  synthetic: {
    groups: {
      popular: {
        mode: "withAll",
        filters: [
          "facilities.motorway_site",
          "facilities.high_speed_pump",
          "facilities.open_24_hours",
          "facilities.petit_bistro",
          "facilities.shop",
          "products.lpg",
          "products.natural_gas",
          "products.ultimate"
        ]
      },
      products: {
        mode: "withAll",
        filters: [
          "products.ad_blue_cannister",
          "products.ad_blue_pump",
          "products.lpg",
          "products.natural_gas",
          "products.truck_diesel",
          "products.ultimate",
          "products.ultimate_diesel"
        ]
      },
      facilities: {
        mode: "withAll",
        filters: [
          "facilities.car_wash",
          "facilities.cash_point",
          "facilities.high_speed_pump",
          "facilities.toilet",
          "facilities.truck_parking",
          "facilities.truck_suitable_site",
          "facilities.jet_wash"
        ]
      },
      food: {
        mode: "withAll",
        filters: [
          "facilities.lotto",
          "facilities.bistro",
          "facilities.petit_bistro",
          "facilities.restaurant",
          "facilities.shop",
          "facilities.tobacco",
          "facilities.alcohol"
        ]
      }
    },
    canonical: true
  }
}
