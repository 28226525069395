import React from "react"
import MapContainer from "@geome/react-components/lib/containers/map"
import View from "./view"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"

const Map = (props) => (
  <MapContainer>{(viewProps) => <View {...viewProps} {...props} />}</MapContainer>
)

const queries = {
  isShowingTraffic: "map.isShowingTraffic",
  isClustering: "locations.isClustering",
  directions: "directions.directions",
  showStationsOnRoute: "directions.options.showStationsOnRoute",
  directionsStep: "layout.directionsStep",
}

export default withContexts(EnvironmentContext)(connectToFlux(queries, {})(Map))
