import React from "react"
import PropTypes from "prop-types"

const ArrowDownIcon = ({ className }) => {
  return (
    <svg
      className={className || ""}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="11"
      viewBox="0 0 17 11"
    >
      <path
        fill="#0064CC"
        fillRule="nonzero"
        d="M0 2.075L2.004 0l6.499 6.849L14.996 0 17 2.075 8.503 11z"
      />
    </svg>
  )
}
ArrowDownIcon.propTypes = {
  className: PropTypes.string
}
export default ArrowDownIcon
