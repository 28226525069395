import BaseAnalyticsStore from "@geome/sdk/lib/app/store/analytics"

class AnalyticsStore extends BaseAnalyticsStore {
  static initClass() {
    this.addActions({
      WATCHLIST_TOGGLED({ payload: { watchlistId } }) {
        if (!this.queryHandler.get("watchlist.list").includes(watchlistId)) {
          const location = this.queryHandler
            .get("locations.views.pool")
            .find(({ watchlist_id }) => watchlistId === watchlist_id)

          this.addToQueue({
            category: "station finder",
            label: location.name,
            action: "click watchlist",
          })
        }
      },
      LOCATION_SELECTED({ payload: location }) {
        if (location) {
          this.addToQueue({
            category: "station finder",
            label: location.name,
            action: "station finder view",
          })
        }
      },
    })
  }
}

AnalyticsStore.initClass()

export default AnalyticsStore
