import React, { useState, useEffect } from "react"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import Modal from "@geome/react-components/lib/components/modal"
import PropTypes from "prop-types"
import withI18n from "@geome/react-next/lib/withI18n"

const SaveModal = ({
  saveDirections,
  saveRouteErrors,
  onClose,
  hasSavedRoute,
  clearSaveRouteStatus,
  translate,
}) => {
  const [name, setName] = useState("")

  useEffect(() => {
    if (hasSavedRoute) onClose()
    return () => clearSaveRouteStatus()
  }, [hasSavedRoute])

  return (
    <Modal windowClassName="saved-routes__save-modal">
      <div className="saved-routes__save-modal-title">
        {translate("saved_routes.save_modal.title")}
      </div>
      <input
        className="saved-routes__save-modal-input"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={translate("saved_routes.save_modal.placeholder")}
      />
      {saveRouteErrors && (
        <div className="saved-routes__save-modal-errors">{saveRouteErrors.join(", ")}</div>
      )}
      <div className="u-flex-row u-flex-justify-end">
        <button className="saved-routes__save-modal-button-cancel" onClick={onClose}>
          {translate("saved_routes.save_modal.cancel")}
        </button>
        <button
          className="saved-routes__save-modal-button-save"
          onClick={() => saveDirections(name)}
        >
          {translate("saved_routes.save_modal.save")}
        </button>
      </div>
    </Modal>
  )
}

SaveModal.propTypes = {
  saveDirections: PropTypes.func,
  translate: PropTypes.func,
  onClose: PropTypes.func,
  hasSavedRoute: PropTypes.bool,
  clearSaveRouteStatus: PropTypes.func,
  saveRouteErrors: PropTypes.arrayOf(PropTypes.string),
  route: PropTypes.shape({ waypoints: PropTypes.array }),
}

const commands = {
  saveDirections: "directions.save",
  clearSaveRouteStatus: "directions.clearSaveRouteStatus",
}

const queries = {
  hasSavedRoute: "directions.savedStatus.hasSaved",
  saveRouteErrors: "directions.savedStatus.errors.name",
}

export { SaveModal as UnwrappedSaveModal }
export default withI18n(connectToFlux(queries, commands)(SaveModal))
