export default {
  products: [
    "ultimate_bp_clear_super",
    "ultimate_diesel_500",
    "ultimate_diesel_50",
    "ad_blue_cannister",
    "ultimate_unleaded",
    "ultimate_diesel",
    "ultimate_ulp_93",
    "ultimate_super",
    "regular_diesel",
    "premium_unlead",
    "swappa_bottle",
    "aa_smart_fuel",
    "ad_blue_pump",
    "ultimate_102",
    "truck_diesel",
    "unleaded_91",
    "ultimate_98",
    "natural_gas",
    "unleaded_95",
    "red_diesel",
    "regular_92",
    "ultimate",
    "auto_gas",
    "ad_blue",
    "unlead",
    "lpg"
  ],
  facilities: [
    "mobile_enabled",
    "cash_point",
    "ms_simply_food",
    "toilet",
    "wild_bean_cafe",
    "outdoor_payment_terminal",
    "lorry_and_truck_terminal",
    "ultra_high_flow_diesel",
    "accepts_toll_payments",
    "truck_driver_lounge",
    "disabled_facilities",
    "truck_suitable_site",
    "credit_card_maestro",
    "shop_open_24_hours",
    "road_train_access",
    "special_car_wash",
    "bp_connect_store",
    "reminder_service",
    "steers_fishaways",
    "steers_debonairs",
    "high_speed_pump",
    "washing_machine",
    "rock_n_rooster",
    "chicken_licken",
    "automatic_site",
    "bdouble_access",
    "car_wash_plant",
    "barista_coffee",
    "motorway_site",
    "bakery_bistro",
    "truck_parking",
    "western_union",
    "takeaway_food",
    "open_24_hours",
    "tires_pump_up",
    "bike_friendly",
    "loyalty_earn",
    "tyre_service",
    "fresh_pastry",
    "super_marker",
    "dry_cleaning",
    "petit_bistro",
    "pump_rewards",
    "rigid_access",
    "weigh_bridge",
    "loyalty_burn",
    "pnp_express",
    "supermarket",
    "is_domestic",
    "cards_codes",
    "biker_point",
    "bunker_site",
    "super_boxes",
    "fuel_master",
    "car_rental",
    "aral_store",
    "bottle_gas",
    "bonus_club",
    "truck_only",
    "truck_wash",
    "restaurant",
    "tires_pump",
    "super_wash",
    "macdonalds",
    "mcdonalds",
    "gift_card",
    "recycling",
    "hand_wash",
    "jet_wash",
    "trailers",
    "car_wash",
    "workshop",
    "alcohol",
    "loyalty",
    "tobacco",
    "shower",
    "go_box",
    "eftpos",
    "steers",
    "nandos",
    "bistro",
    "merkur",
    "redeem",
    "lotto",
    "wimpy",
    "swipa",
    "shop",
    "wifi",
    "opt",
    "uta",
    "ruc",
    "pay",
    "kfc",
    "vda",
    "dkv",
    "bp"
  ]
}
