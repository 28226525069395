export default {
  number: {
    distance: {
      maximumFractionDigits: 1
    }
  },
  localised: {
    en_GB: {
      number: {
        distance: {
          unit: "miles"
        }
      }
    }
  }
}
