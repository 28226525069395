import React from "react"
import PropTypes from "prop-types"
import WatchlistButtonView from "./view"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import withI18n from "@geome/react-next/lib/withI18n"

const checkWatchlistStatus = (watchlist = [], watchlistId) => watchlist.includes(watchlistId)

const WatchlistButton = (props) => (
  <WatchlistButtonView
    {...props}
    onClick={() => props.toggleWatchlist(props.location)}
    watchlistId={props.location.watchlist_id}
    isOnWatchlist={checkWatchlistStatus(props.watchlist, props.location.watchlist_id)}
  />
)

WatchlistButton.propTypes = {
  className: PropTypes.string,
  watchlistId: PropTypes.string,
  location: PropTypes.shape({
    watchlist_id: PropTypes.string,
  }),
  toggleWatchlist: PropTypes.func,
  watchlist: PropTypes.array,
  translate: PropTypes.func,
  loggedIn: PropTypes.bool,
}

const queries = {
  watchlist: "watchlist.list",
  loggedIn: "config.authentication.logged_in",
}
const commands = {
  toggleWatchlist: "watchlist.toggle",
}

export { WatchlistButton as UnwrappedWatchlistButton }
export default withI18n(connectToFlux(queries, commands)(WatchlistButton))
