import AppLayoutView from "./view"
import React from "react"
import PropTypes from "prop-types"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import LocationsHelper from "@geome/sdk/lib/helper/location"
import { DIRECTIONS_STEPS } from "../../values/directionsSteps"
class AppLayout extends React.Component {
  static propTypes = {
    locations: PropTypes.array,
    selectedLocation: PropTypes.object,
    routes: PropTypes.array,
    isMobile: PropTypes.bool,
    directionsStep: PropTypes.oneOf([null, DIRECTIONS_STEPS.INPUT, DIRECTIONS_STEPS.VIEWING_ROUTE]),
  }

  getActiveWindow() {
    const { isMobile, directionsStep, selectedLocation, routes, locations } = this.props

    if (isMobile && directionsStep === DIRECTIONS_STEPS.INPUT) return
    if (selectedLocation) return "infoWindow"
    if (routes) return "itinerary"
    if (this.isShowingMatchingLocations(locations)) return "locationsList"
  }

  isShowingMatchingLocations(locations = []) {
    return (
      locations.filter(
        (l) => !LocationsHelper.isClustered(l) && l.resource !== "non_matching_locations"
      ).length > 0
    )
  }

  render() {
    return <AppLayoutView {...this.props} activeWindow={this.getActiveWindow()} />
  }
}

const queries = {
  selectedLocation: "locations.selected",
  routes: "directions.directions.routes",
  directions: "directions.directions",
  locations: "locations.views.bounded",
  directionsStep: "layout.directionsStep",
  isShowingFiltersSection: "layout.window.filters.active",
}

export { AppLayout as UnwrappedAppLayout }
export default withContexts(EnvironmentContext)(connectToFlux(queries, {})(AppLayout))
