import React from "react"
import ReactDOM from "react-dom"
import Config from "./config"
import AppComponent from "./components/app"
import ActionTypes from "./values/actionTypes"
import Inject from "@geome/inject"

import DirectionsRenderingFeature from "@geome/sdk/lib/app/feature/directions/rendering"
import DirectionsRoutingFeature from "@geome/sdk/lib/app/feature/directions/routing"
import FluxAnalyticsFeature from "@geome/sdk/lib/app/feature/flux/analytics"
import FluxBasicsFeature from "@geome/sdk/lib/app/feature/flux/basics"
import FluxConfigFeature from "@geome/sdk/lib/app/feature/flux/config"
import FluxDirectionsFeature from "@geome/sdk/lib/app/feature/flux/directions"
import FluxFiltersFeature from "@geome/sdk/lib/app/feature/flux/filters"
import FluxGeolocationFeature from "@geome/sdk/lib/app/feature/flux/geolocation"
import FluxMapFeature from "@geome/sdk/lib/app/feature/flux/map"
import FluxRoutingFeature from "@geome/sdk/lib/app/feature/flux/routing"
import FluxSearchFeature from "@geome/sdk/lib/app/feature/flux/search"
import FluxTrafficFeature from "@geome/sdk/lib/app/feature/flux/traffic"
import GoogleSearchFeature from "@geome/sdk/lib/app/feature/google/search"
import SearchRoutingFeature from "@geome/sdk/lib/app/feature/search/routing"
import TrafficRenderingFeature from "@geome/sdk/lib/app/feature/traffic/rendering"

import GeomeGatewayAnalyticsAdaptor from "@geome/sdk/lib/analytics/adaptors/geomeGateway"
import ConsoleAnalyticsAdaptor from "@geome/sdk/lib/analytics/adaptors/console"

import { initLog } from "@geome/fe-log"
import ChangeOptionsCommand from "./commands/directions/changeOptions"
import ClearSaveRouteStatusCommand from "./commands/directions/clearSaveRouteStatus"
import DeleteRouteDirectionsCommand from "./commands/directions/deleteRoute"
import LoadDirectionsCommand from "./commands/directions/load"
import RequestRoutesDirectionsCommand from "./commands/directions/requestRoutes"
import SaveDirectionsCommand from "./commands/directions/save"
import SetDirectionsStepCommand from "./commands/layout/setDirectionsStep"
import SetWaypointPlaceCommand from "./commands/directions/setWaypointPlace"
import SwitchWaypointsCommand from "./commands/directions/switchWaypoints"
import WatchlistToggleCommand from "./commands/watchlist/toggle"

import LayoutStore from "./stores/layout"
import AnalyticsStore from "./stores/analytics"
import WatchlistStore from "./stores/watchlist"
import DirectionsStore from "./stores/directions"
import FiltersStore from "@geome/sdk/lib/app/store/filters"
import SearchComponent from "./components/search"
import DirectionsComponent from "./components/directions"
import analyticsTransform from "./analytics/transform"

import "../iframe-test.html"

const appVersion = document.querySelector("html").dataset.geomeVersion
const deployEnv = process.env.DEPLOY_ENV

initLog({
  logglyKey: "89f85593-200b-45dd-a38f-0a12d24e0a62",
  appName: "aralretaillocator",
  deployEnv,
  appVersion,
})

const { feature, resolve, register, initializer, start, inject } = Inject()

feature({
  DirectionsRenderingFeature,
  DirectionsRoutingFeature,
  FluxAnalyticsFeature,
  FluxBasicsFeature,
  FluxConfigFeature,
  FluxDirectionsFeature,
  FluxFiltersFeature,
  FluxGeolocationFeature,
  FluxMapFeature,
  FluxRoutingFeature,
  FluxSearchFeature,
  FluxTrafficFeature,
  GoogleSearchFeature,
  SearchRoutingFeature,
  TrafficRenderingFeature,
})

inject("routing::component::search", () => SearchComponent)
inject("routing::component::directions", () => DirectionsComponent)

register("registry::routes", {
  "/"() {
    return {
      match: ["/"],
      component: SearchComponent,
    }
  },
})

register("registry::resources", {
  locations: () => ({
    views: {
      bounded: {
        type: "bounded",
      },
      radial: {
        type: "radial",
        omitParams: ["radius"],
      },
      singleton: {
        type: "singleton",
      },
    },
  }),
  non_matching_locations: () => ({
    views: {
      bounded: {
        type: "bounded",
      },
    },
  }),
})

register("registry::flux::commands", {
  "directions.changeOptions": (app) => ({
    class: ChangeOptionsCommand,
    deps: {
      convert: app.resolve("helper::convert"),
      i18n: app.resolve("i18n"),
    },
  }),
  "directions.setWaypointPlace": () => ({
    class: SetWaypointPlaceCommand,
  }),
  "directions.switchWaypoints": () => ({
    class: SwitchWaypointsCommand,
  }),
  "directions.load": (app) => ({
    class: LoadDirectionsCommand,
    deps: {
      placeSearchUtil: app.resolve("util::place::search"),
    },
  }),
  "directions.save": (app) => ({
    class: SaveDirectionsCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
    },
  }),
  "directions.deleteRoute": (app) => ({
    class: DeleteRouteDirectionsCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
    },
  }),
  "directions.clearSaveRouteStatus": () => ({
    class: ClearSaveRouteStatusCommand,
  }),
  "directions.requestRoutes": (app) => ({
    class: RequestRoutesDirectionsCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
    },
  }),
  "layout.setDirectionsStep": () => ({
    class: SetDirectionsStepCommand,
  }),
  "watchlist.toggle": (app) => ({
    class: WatchlistToggleCommand,
    deps: {
      httpClient: app.resolve("httpClient"),
    },
  }),
})

register("registry::flux::stores", {
  directions: () => ({ class: DirectionsStore }),
  layout: () => ({ class: LayoutStore }),
  watchlist: () => ({ class: WatchlistStore }),
  filters: () => ({ class: FiltersStore }),
  analytics: () => ({ class: AnalyticsStore }),
})

inject("analytics::transform", () => analyticsTransform)

register("registry::flux::actionTypes", ActionTypes)

if (process.env.DEPLOY_ENV !== "prod") {
  inject("analytics::adaptor", () => new ConsoleAnalyticsAdaptor({}))
} else {
  inject(
    "analytics::adaptor",
    () =>
      new GeomeGatewayAnalyticsAdaptor({
        googleAnalyticsId: "UA-39652852-6",
      })
  )
}

initializer("Set Directions Options", (app) => {
  const directionsOptions = app.resolve("config").get("directions.options")
  app.resolve("flux::commandHandler").run("directions.initializeOptions", directionsOptions)
})

initializer("App", () => render(AppComponent))

const getAppProps = () => ({
  get() {},
  i18n: resolve("i18n"),
  commandHandler: resolve("flux::commandHandler"),
  queryHandler: resolve("flux::queryHandler"),
  routingHistory: resolve("routing::history"),
  routingRegistry: resolve("routing::registry"),
  mapFactory: resolve("map::factory"),
  routerProps: {
    routes: resolve("routing::registry").getComponentRoutes(),
    history: resolve("routing::history"),
  },
})

const render = (Component) => {
  ReactDOM.render(<Component {...getAppProps()} />, document.getElementById("app"))
}

document.addEventListener("DOMContentLoaded", () => start(Config))
