import { Command } from "@geome/flux"
import { distance } from "@geome/intl/lib/convert"

class ChangeOptions extends Command {
  execute(directionsOptions) {
    const distanceUnit = this.query("i18n.intlData.number.distance.unit")
    const corridorRadiusKm = distance(directionsOptions.corridorRadius, distanceUnit, "km")
    const newResourceConstraints = { radius: corridorRadiusKm }
    this.dispatchActions(directionsOptions, newResourceConstraints)
  }

  dispatchActions(directionsOptions, resourceConstraints) {
    this.dispatch({
      type: this.actionTypes.DIRECTIONS_OPTIONS_CHANGED,
      payload: directionsOptions,
    })
    this.dispatch({
      type: this.actionTypes.RESOURCES_CONSTRAINTS_CHANGED,
      payload: resourceConstraints,
    })
  }
}

export default ChangeOptions
