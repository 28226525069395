import defaults from "./locales/default"

const localised = {}
const context = require.context("./locales", false, /\.js$/)
context.keys().forEach(key => {
  localised[key.replace("./", "").replace(".js", "")] =
    context(key).default === undefined ? context(key) : context(key).default
})

export default { ...defaults, localised }
