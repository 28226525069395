import React from "react"
import PropTypes from "prop-types"
import GeolocationButton from "@geome/react-components/lib/components/geolocationButton"
import GeolocationIcon from "../../../assets/icons/geoLocation"
import withI18n from "@geome/react-next/lib/withI18n"

class AralGeolocationButtonView extends React.Component {
  static propTypes = {
    translate: PropTypes.func.isRequired,
    geolocationStatus: PropTypes.string,
    geolocationContext: PropTypes.string,
    className: PropTypes.string,
    isGeolocationAvailable: PropTypes.bool,
    onClick: PropTypes.func,
  }

  renderGeoLocationIcon() {
    return {
      idle: <GeolocationIcon className="geolocation-button--idle" />,
      busy: <div className="geolocation-button--busy" />,
      active: <GeolocationIcon className="geolocation-button--active" />,
      error: <GeolocationIcon className="geolocation-button--error" />,
    }[this.props.geolocationStatus || "idle"]
  }

  render() {
    if (!this.props.isGeolocationAvailable) return null
    const { translate, geolocationStatus, ...props } = this.props
    const tooltipText = translate(
      `geolocation.tooltip.${geolocationStatus === "error" ? "error" : "text"}`
    )
    return (
      <div className="search__render-to-left">
        <GeolocationButton
          {...props}
          tooltipText={tooltipText}
          renderIcon={() => this.renderGeoLocationIcon()}
        />
      </div>
    )
  }
}

export default withI18n(AralGeolocationButtonView)
