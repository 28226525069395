import Store from "@geome/flux/lib/store"

class Watchlist extends Store {
  static initClass() {
    this.addActions({
      WATCHLIST_TOGGLED({ payload }) {
        this.toggleLocation(payload.watchlistId)
      },
      WATCHLIST_LOADED({ payload }) {
        this.set("list", payload.list)
      },
    })
  }

  constructor(...args) {
    super(...args)
    this.initialized = false
  }

  initialize() {
    if (this.initialized === true) return
    this.initialized = true

    window.parent.postMessage({ event: "ready-watchlist" }, "*")
    this.set("list", [])

    // changes shouldn't originate from within the store, but it should be fine here
    this.setupReceiveListener()
  }

  setupReceiveListener() {
    window.addEventListener("message", ({ data }) => {
      if (data.event === "watchlist") {
        this.set("list", data.ids)
      }
    })
  }

  toggleLocation(watchlistId) {
    const currentList = this.get("list")
    if (currentList.includes(watchlistId)) {
      this.set(
        "list",
        currentList.filter((l) => l !== watchlistId)
      )
    } else {
      this.set("list", currentList.concat([watchlistId]))
    }
  }
}

Watchlist.initClass()

export default Watchlist
