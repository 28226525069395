import React from "react"
import PropTypes from "prop-types"
import GeolocationButtonContainer from "@geome/react-components/lib/containers/geolocationButton"
import AralGeolocationButtonView from "./view"
import connectToFlux from "@geome/react-next/lib/connectToFlux"

class AralGeolocationButton extends React.Component {
  static propTypes = {
    isGeolocationAvailable: PropTypes.bool,
  }

  render() {
    return (
      <GeolocationButtonContainer>
        {(props) => <AralGeolocationButtonView {...props} {...this.props} />}
      </GeolocationButtonContainer>
    )
  }
}

const queries = {
  isGeolocationAvailable: "geolocation.isAvailable",
}

export default connectToFlux(queries, {})(AralGeolocationButton)
