import React from "react"
import PropTypes from "prop-types"

const WatchlistButtonView = ({
  className,
  onClick,
  translate,
  isOnWatchlist,
  watchlistId,
  loggedIn,
}) => {
  if (!loggedIn || !watchlistId) return null
  return (
    <button className={`watchlist-button ${className || ""}`} onClick={onClick}>
      {translate(`watchlist.${isOnWatchlist ? "remove" : "add"}`)}
    </button>
  )
}

WatchlistButtonView.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  translate: PropTypes.func,
  isOnWatchlist: PropTypes.bool,
  loggedIn: PropTypes.bool,
  watchlistId: PropTypes.string,
}

export default WatchlistButtonView
