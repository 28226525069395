import DirectionsStoreBase from "@geome/sdk/lib/app/store/directions"

class Directions extends DirectionsStoreBase {
  static initClass() {
    this.addActions({
      DIRECTIONS_WAYPOINTS_LIMIT_SET(action) {
        this.set("waypointsLimit", action.payload)
      },
      DIRECTIONS_WAYPOINT_ADDED(action) {
        this.addWaypoint(action.payload.index)
        this.updateWaypointsLimitReached()
      },
      DIRECTIONS_WAYPOINT_REMOVED(action) {
        this.removeWaypoint(action.payload.name)
        this.updateWaypointsLimitReached()
      },
      DIRECTIONS_WAYPOINT_CLEARED(action) {
        this.clearWaypoint(action.payload.name)
        this.updateWaypointsLimitReached()
      },
      DIRECTIONS_ROUTES_LOADED(action) {
        this.set("savedRoutes", action.payload)
      },
      DIRECTIONS_SAVED_ROUTE_DELETED(action) {
        this.removeSavedRoute(action.payload)
      },
      DIRECTIONS_SAVE_ROUTE_STATUS_SET(action) {
        if (action.error) {
          this.set("savedStatus.errors", action.error.errors)
        } else {
          this.set("savedStatus", { hasSaved: action.payload, errors: null })
        }
      },
    })
  }

  initialize() {
    super.initialize(...arguments)
    this.set("waypointsLimitReached", false)
    this.set("savedRoutes", [])
    this.set("savedStatus", { hasSaved: false, errors: null })
  }

  updateWaypointsLimitReached() {
    const waypoints = this.get("waypoints")
    const waypointsLimit = this.get("waypointsLimit")
    const numberOfWaypoints = waypoints.length - 2
    this.set("waypointsLimitReached", numberOfWaypoints >= waypointsLimit)
  }

  removeSavedRoute(id) {
    const savedRoutes = this.get("savedRoutes")
    this.set(
      "savedRoutes",
      savedRoutes.filter((r) => r.id !== id)
    )
  }
}

Directions.initClass()

export default Directions
