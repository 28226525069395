import Command from "@geome/flux/lib/command"

class SetWaypointPlace extends Command {
  execute({ name, place }) {
    this.dispatch({
      type: this.actionTypes.DIRECTIONS_WAYPOINT_SET,
      payload: { name, place },
    })
  }
}

export default SetWaypointPlace
