import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import ListModal from "../listModal"
import withI18n from "@geome/react-next/lib/withI18n"
import connectToFlux from "@geome/react-next/lib/connectToFlux"

const ViewRoutesButton = ({ translate, loggedIn }) => {
  const [showingModal, toggleModal] = useState(false)

  if (!loggedIn) return null
  return (
    <Fragment>
      <button className="saved-routes__view-routes-button" onClick={() => toggleModal(true)}>
        {translate("saved_routes.title")}
      </button>
      {showingModal && <ListModal onClose={() => toggleModal(false)} />}
    </Fragment>
  )
}

ViewRoutesButton.propTypes = {
  translate: PropTypes.func,
  loggedIn: PropTypes.bool,
}

const queries = {
  loggedIn: "config.authentication.logged_in",
}

export { ViewRoutesButton as UnwrappedViewRoutesButton }
export default connectToFlux(queries, {})(withI18n(ViewRoutesButton))
