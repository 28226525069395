import React from "react"
import PropTypes from "prop-types"

const ChevronUpIcon = ({ className }) => {
  return (
    <svg className={className || ""} xmlns="http://www.w3.org/2000/svg" width="17" height="10">
      <path
        fill="#FFFFFF"
        fillRule="evenodd"
        d="M16.272 7.89L14.86 9.271 8.498 3.038l-6.36 6.234-1.41-1.383L8.498.272l7.774 7.617"
      />
    </svg>
  )
}
ChevronUpIcon.propTypes = {
  className: PropTypes.string
}

export default ChevronUpIcon
