import React from "react"
import PropTypes from "prop-types"

const AddIcon = ({ className }) => {
  return (
    <svg
      className={className || ""}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path fillRule="nonzero" d="M11.667 6.667h-5v5H5v-5H0V5h5V0h1.667v5h5z" />
    </svg>
  )
}
AddIcon.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool
}
export default AddIcon
