export default {
  distance: {
    value: "{distance, number, distance} {unit}",
    onlyUnit: "{unit}",
    unit: {
      miles: "km",
    },
  },
  search: {
    input_placeholder: "Ort suchen",
    suggestions: "Suggestions",
  },
  info_window: {
    directions: {
      button: "Route anzeigen",
    },
    website_button: "Zur Tankstellen-Webseite",
    services: {
      title: "Services",
    },
    fuel_prices: {
      title: "Kraftstoffpreise",
      timestamp: "Letzte Aktualisierung {lastUpdatedOn} Uhr.",
      disclaimer: "Keine Garantie. Es gilt der Preis an der Zapfsäule.",
    },
    opening: {
      title: "Öffnungszeiten",
    },
    opening_hours: {
      open_twentyfourhours: "24 Stunden geöffnet",
      mon: "Mo.",
      tue: "Di.",
      wed: "Mi.",
      thu: "Do.",
      fri: "Fr.",
      sat: "Sa.",
      sun: "So.",
      title: "Öffnungszeiten",
      period: "{start, time, short} – {end, time, short}",
    },
  },
  locations_list: {
    title: "Stationen in der Nähe",
  },
  traffic_button: {
    shown: "Verkehr AUS",
    hidden: "Verkehr AN",
  },
  watchlist: {
    add: "Zu „Meine Tankstellen“ hinzufügen",
    remove: "Aus „Meine Tankstellen“ entfernen",
  },
  saved_routes: {
    title: "Gespeicherte Routen",
    list: {
      title: "Gespeicherte Routen",
    },
    save_modal: {
      title: "Route sichern ",
      placeholder: "Route Name",
      error: " Es ist ein Fehler aufgetreten",
      cancel: "Cancel",
      save: "Route sichern ",
    },
    save_button: {
      text: "Route sichern ",
    },
  },
  geolocation: {
    tooltip: {
      text: "Standort erlauben",
      error: "Standortbestimmung zulassen (Einstellungen)",
    },
  },
  opening_hours: {
    twenty_four_hour: "24 Stunden geöffnet",
    open: "Jetzt geöffnet",
    closed: "Closed Now",
  },
  directions: {
    route: {
      avoid_highways: "Autobahn vermeiden",
      avoid_tolls: "Mautstellen vermeiden",
      show_stations_on_route: "Tankstellen entlang der Route",
      find: "Route anzeigen",
      new: "Neue Route",
      title: "Routenoptionen",
      edit: "Route bearbeiten",
      title_corridor: "Suche nach Entfernung nach Tankstellen entlang der Route",
    },
    placeholder: {
      destination: "Nach",
      origin: "Von",
      waypoint: "Via",
    },
    help: {
      remove: "Remove",
      switch_waypoints: "Switch from and to",
      add_waypoint: "Ziel hinzufügen",
    },
  },
  itinerary: {
    title: "Ziel hinzufügen",
    from: "Von",
    to: "Nach",
    totals: {
      full: "Total {durationText} ({distanceText})",
      distance: "{distance, number, distance} {unit}",
      duration:
        "{weeks, plural, =0 {} =1 {# wk} other {# wks}} {days, plural, =0 {} =1 {# day} other {# days}} {hours, plural, =0 {} =1 {# hr} other {# hrs}} {minutes, plural, =0 {} =1 {# min} other {# mins}}",
    },
  },
  filters: {
    title: "Filter",
    clear: "Filter löschen",
    title_applied: "Filters ({count} applied)",
    popular: {
      title: "Beliebte Filter",
    },
    food: {
      title: "Food",
    },
    car_wash: {
      title: "Autowäsche",
    },
    facilities: {
      title: "Services",
    },
    products: {
      title: "Spezielle Kraftstoffe",
    },
  },
  general_buttons: {
    back: "Zurück",
  },
  facilities: {
    aral_store: "Aral Store",
    bp_connect_store: "BP-Connect-Store",
    bunker_site: "Tank",
    motorway_site: "Autobahntankstelle",
    accepts_routex_cards: "Annahme nach Routex",
    accepts_toll_payments: "Mautstation",
    reminder_service: "Erinnerungsservice",
    car_wash: "Waschanlage",
    cash_point: "Geldautomat",
    disabled_facilities: "Behindertengerechte Einrichtungen",
    high_speed_pump: "Hochleistungssäule",
    lorry_and_truck_terminal: "LKW-Terminal",
    lotto: "Lotto",
    toilet: "Toilette",
    pump_rewards: "Pump Rewards",
    truck_parking: "Autohof",
    truck_wash: "LKW-Waschanlage",
    truck_suitable_site: "LKW-geeigneter Standort",
    wifi: "WLAN",
    loyalty: "PAYBACK",
    car_rental: "Car Rental",
    ruc: "Road User Charges",
    trailers: "Trailer Hire",
    bonus_club: "BP Bonusclub",
    go_box: "Go Box",
    truck_only: "Truckstop",
    super_wash: "Aral SuperWash",
    car_wash_plant: "Waschstraßen",
    super_boxes: "SB Waschplätze",
    vda: "VDA Konform Siegel",
    jet_wash: "Cabriopflege",
    special_car_wash: "SUV Pflege",
    open_24_hours: "24 Stunden geöffnet",
    automatic_site: "BP express",
    swappa_bottle: "Swappa Bottle LPG",
    biker_point: "Bikerpoint",
    tyre_service: "Reifenservice",
    uta: "UTA",
    credit_card_maestro: "Kreditkarten + Maestro",
    dkv: "DKV",
    bike_friendly: "Biker-freundlich",
    rewe_to_go: "REWE To Go",
    bakery_bistro: "Bäckerei-Bistro",
    bistro: "Bistro",
    ms_simply_food: "M&S Simply Food",
    petit_bistro: "PetitBistro",
    restaurant: "Restaurant",
    shop: "Shop",
    shop_open_24_hours: "Shop 24 h",
    supermarket: "Supermarkt",
    tobacco: "Tabak",
    alcohol: "Alkohol",
    wild_bean_cafe: "Wild Bean Café",
    merkur: "MERKUR inside",
    bottle_gas: "BP Flaschenglas",
    mobile_enabled: "Mobile Enabled",
    too_good_to_go: "Too Good To Go",
  },
  products: {
    ad_blue_cannister: "AdBlue Kanister",
    ad_blue_pump: "AdBlue-Pumpe",
    aa_smart_fuel: "AA Smartfuel",
    lpg: "LPG",
    auto_gas: "Autogas",
    natural_gas: "Erdgas",
    red_diesel: "Roter Diesel",
    truck_diesel: "LKW Diesel",
    ultimate: "Ultimate",
    ultimate_102: "Ultimate 102",
    ultimate_diesel: "Ultimate Diesel",
    ultimate_unleaded: "BP Ultimate bleifrei",
    super_e5: "Super E5",
    super_98: "Super E10",
    ad_blue: "AdBlue",
    ultimate_98: "Super Plus Additiviert",
    unleaded_91: "Normalbenzin",
    unleaded_95: "Eurosuper",
    regular_diesel: "Diesel",
    ultimate_super: "BP Ultimate Super",
    diesel_add: "Diesel Additiviert",
    unleaded_91_add: "Benzin Additiviert",
    unleaded_95_add: "Eurosuper additiviert",
    unleaded_98: "Super Plus",
    diesel_frost: "Alpin-Diesel ",
    futura_fuel_oil: "Heizoel Extraleicht Futura",
    euro_95: "Euro 95 with 10% Ethanol",
    fuel_oil: "Heizoel Extraleicht",
    super_wash: "Aral SuperWash",
    car_wash_plant: "Waschstraßen",
    super_boxes: "SB Waschplätze",
    vda: "VDA Konform Siegel",
    special_car_wash: "SUV Pflege",
  },
}
