import React from "react"
import PropTypes from "prop-types"
import ModalBase from "@geome/react-components/lib/components/modal"
import CloseIcon from "../../../assets/icons/close"

class Modal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
  }

  renderHeader() {
    const { onClose, title } = this.props

    if (!onClose) return null
    return (
      <div className="modal__window-header">
        <div className="modal__window-header-title">{title}</div>
        <button className="modal__window-header-close-button" onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
    )
  }

  render() {
    return (
      <ModalBase {...this.props}>
        {this.renderHeader()}
        {this.props.children}
      </ModalBase>
    )
  }
}

export default Modal
