import React from "react"
import OpeningHoursEntry from "./entry"
import PropTypes from "prop-types"

class OpeningHours extends React.Component {
  static propTypes = {
    openingHours: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired
  }

  is24Hour(entry) {
    return entry[0] === "00:00" && entry[1] === "23:59"
  }

  translateDay(day) {
    const { translate } = this.props
    return translate(`info_window.opening_hours.${day.toLowerCase()}`)
  }

  daysForEntry(entry) {
    return entry.days.map(day => this.translateDay(day)).join(" – ")
  }

  timesForEntry(entry) {
    const { translate } = this.props
    return entry.hours.map(period => {
      if (this.is24Hour(period)) {
        return translate("info_window.opening_hours.open_twentyfourhours")
      } else {
        return translate("info_window.opening_hours.period", {
          start: this.timeFrom(period[0]),
          end: this.timeFrom(period[1])
        })
      }
    })
  }

  timeFrom(string) {
    const d = new Date()
    d.setHours(string.split(":")[0])
    return d.setMinutes(string.split(":")[1])
  }

  rowForEntry(entry) {
    return (
      <OpeningHoursEntry
        days={this.daysForEntry(entry)}
        times={this.timesForEntry(entry)}
        key={`openingHours_${this.daysForEntry(entry)}`}
      />
    )
  }

  render() {
    if (!this.props.openingHours) return null
    return (
      <div className="info-window__column">
        {this.props.openingHours.map(entry => this.rowForEntry(entry))}
      </div>
    )
  }
}

export default OpeningHours
