import React from "react"
import CloseIcon from "../../../../assets/icons/close"
import PropTypes from "prop-types"

const RemoveButton = ({ onClick, waypointName, index, isRemovable }) => {
  if (index === 0 || !isRemovable) return null
  return (
    <button
      id={`directions__remove-button-${waypointName}`}
      className={`directions__remove-waypoint-button directions__remove-waypoint-button-${index}`}
      onClick={onClick}
    >
      <CloseIcon />
    </button>
  )
}

RemoveButton.propTypes = {
  onClick: PropTypes.func,
  waypointName: PropTypes.string,
  index: PropTypes.number,
  isRemovable: PropTypes.bool
}

export default RemoveButton
