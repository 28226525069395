const analyticsTransform = (analyticEvent, { queryHandler }) => {
  const { products = [], facilities = [] } = queryHandler.get("filters.constraints.withAll") || {}
  const { category, action, label, ...otherAnalyticThings } = analyticEvent
  return {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    ...otherAnalyticThings,
    dimension21: products.join(","),
    dimension22: facilities.join(","),
  }
}

export default analyticsTransform
