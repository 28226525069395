import React, { Fragment } from "react"
import PropTypes from "prop-types"
import ObserveProps from "@geome/react-next/lib/util/observeProps"
import PointRenderer from "@geome/react-components/lib/components/map/renderer/point"
import ClusterRenderer from "@geome/react-components/lib/components/map/renderer/cluster"
import SearchMarkerRenderer from "@geome/react-components/lib/components/map/renderer/searchRenderer"
import Cluster from "@geome/react-components/lib/components/cluster"
import AralMarker from "./aralMarker"

class Locations extends React.Component {
  static propTypes = {
    isClustering: PropTypes.bool.isRequired,
    locations: PropTypes.array.isRequired,
    selectedLocation: PropTypes.object,
    highlightedLocation: PropTypes.object,
    onOverlayRequest: PropTypes.func.isRequired,
    selectedPlace: PropTypes.object,
    shouldRender: PropTypes.bool,
  }

  shouldComponentUpdate(nextProps) {
    return ObserveProps(this.props, nextProps, [
      "locations",
      "selectedLocation",
      "highlightedLocation",
      "selectedPlace",
      "isClustering",
      "shouldRender",
    ])
  }

  renderableLocations() {
    if (!this.props.isClustering) return this.props.locations
    return this.props.locations.filter((l) => l.resource !== "non_matching_locations")
  }

  render() {
    const { shouldRender, selectedLocation, highlightedLocation, onOverlayRequest, selectedPlace } =
      this.props
    const renderableLocations = this.renderableLocations()

    if (!shouldRender) return null
    return (
      <Fragment>
        <PointRenderer
          subjects={renderableLocations}
          selected={selectedLocation}
          highlighted={highlightedLocation}
          childComponent={AralMarker}
          overlay={onOverlayRequest("locations-points")}
          offset={{ left: 24, bottom: 20 }}
        />
        <ClusterRenderer
          subjects={renderableLocations}
          childComponent={Cluster}
          overlay={onOverlayRequest("locations-clusters")}
        />
        <SearchMarkerRenderer selectedPlace={selectedPlace} onOverlayRequest={onOverlayRequest} />
      </Fragment>
    )
  }
}

export default Locations
