import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import ArrowDownIcon from "../../../../assets/icons/arrowDown"
import Modal from "../../../shared/modal"

class RouteOptions extends React.Component {
  static TOGGLE_OPTIONS = {
    avoidTolls: "avoid_tolls",
    avoidHighways: "avoid_highways",
    showStationsOnRoute: "show_stations_on_route"
  }

  static RADIUS_OPTIONS = [1, 2, 3, 4, 5]

  static propTypes = {
    directionsOptions: PropTypes.object,
    onChangeDirectionsOptions: PropTypes.func,
    onToggleRouteSettings: PropTypes.func,
    translate: PropTypes.func,
    isShowingRouteOptions: PropTypes.bool,
    isMobile: PropTypes.bool
  }

  handleOptionToggle(options, name) {
    const newValue = !options[name]
    const newOptions = { ...options, [name]: newValue }
    this.props.onChangeDirectionsOptions(newOptions)
  }

  handleCorridorRadiusChange(radius) {
    const newOptions = {
      ...this.props.directionsOptions,
      corridorRadius: radius
    }
    this.props.onChangeDirectionsOptions(newOptions)
  }

  renderOptionToggles() {
    const { directionsOptions, translate } = this.props
    const toggleDirectionsOptions = _.pick(
      directionsOptions,
      Object.keys(RouteOptions.TOGGLE_OPTIONS)
    )

    return Object.keys(toggleDirectionsOptions).map(optionKey => (
      <div key={optionKey} className="route-options__window-toggle">
        <button
          id={`route-options__window-toggle-${optionKey}`}
          className={`route-options__window-toggle-button ${
            toggleDirectionsOptions[optionKey] ? "route-options__window-toggle-button--active" : ""
          }`}
          onClick={() => this.handleOptionToggle(directionsOptions, optionKey)}
        >
          {translate(`directions.route.${RouteOptions.TOGGLE_OPTIONS[optionKey]}`)}
        </button>
      </div>
    ))
  }

  renderCorridorRadiusOptions() {
    const { directionsOptions } = this.props

    return RouteOptions.RADIUS_OPTIONS.map(radiusOption => (
      <button
        id={`route-options__window-corridor-radius-button-${radiusOption}`}
        key={radiusOption}
        onClick={() => this.handleCorridorRadiusChange(radiusOption)}
        className={`route-options__window-corridor-radius-button ${
          directionsOptions.corridorRadius === radiusOption
            ? "route-options__window-corridor-radius-button--active"
            : ""
        }`}
      >
        {radiusOption}
      </button>
    ))
  }

  renderCorridorRadius() {
    const { directionsOptions, translate } = this.props
    const distanceUnit = translate("distance.onlyUnit", {
      distance: directionsOptions["corridorRadius"],
      convert: false
    })

    return (
      <div className="route-options__window-corridor-radius">
        <div className="route-options__window-corridor-radius-title">
          {`${translate("directions.route.title_corridor")} (${distanceUnit})`}
        </div>
        <div className="route-options__window-corridor-radius-buttons">
          {this.renderCorridorRadiusOptions()}
        </div>
      </div>
    )
  }

  renderOptions() {
    const { isMobile } = this.props

    return (
      <div className={`route-options__window ${isMobile ? "route-options__window--mobile" : ""}`}>
        <div className="route-options__window-toggles">{this.renderOptionToggles()}</div>
        {this.renderCorridorRadius()}
      </div>
    )
  }

  renderToggle() {
    const { onToggleRouteSettings, translate, isShowingRouteOptions } = this.props

    return (
      <div
        className={`route-options__toggle ${
          isShowingRouteOptions ? "route-options__toggle--open" : ""
        }`}
        onClick={onToggleRouteSettings}
      >
        <div className="route-options__toggle-title">{translate("directions.route.title")}</div>
        <ArrowDownIcon
          className={`route-options__toggle-icon ${
            isShowingRouteOptions ? "route-options__toggle-icon--rotate-180" : ""
          }`}
        />
      </div>
    )
  }

  renderRouteOptions() {
    const { isMobile, onToggleRouteSettings, translate } = this.props

    if (!isMobile) return this.renderOptions()
    return (
      <Modal onClose={onToggleRouteSettings} title={translate("directions.route.title")}>
        {this.renderOptions()}
      </Modal>
    )
  }

  render() {
    const { isShowingRouteOptions } = this.props

    return (
      <div className="route-options">
        {this.renderToggle()}
        {isShowingRouteOptions && this.renderRouteOptions()}
      </div>
    )
  }
}

export default RouteOptions
