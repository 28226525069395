import React from "react"
import withI18n from "@geome/react-next/lib/withI18n"
import PropTypes from "prop-types"
import FilterHeader from "./header"
import Filters from "@geome/react-components/lib/components/filters"
import Modal from "../../shared/modal"

class FiltersView extends React.Component {
  static propTypes = {
    renderAsModal: PropTypes.bool,
    isMobile: PropTypes.bool,
    isShowingFiltersSection: PropTypes.bool.isRequired,
    onToggleFiltersSection: PropTypes.func.isRequired,
    activeFiltersCount: PropTypes.number.isRequired,
    translate: PropTypes.func.isRequired,
    onFilterToggle: PropTypes.func.isRequired,
    filterGroups: PropTypes.array.isRequired,
    onClearFiltersClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isShowingFiltersSection: false,
    renderAsModal: false,
    activeFiltersCount: 0,
    filterGroups: [],
  }

  renderFilters() {
    return (
      <Filters
        {...this.props}
        onFilterToggle={(filter) => {
          this.props.onFilterToggle(filter)

          window.parent.postMessage(
            {
              event: "filter_active",
              filter_group: filter.group,
              filter_name: filter.name,
              timestamp: new Date(),
            },
            "*"
          )
        }}
        isMobile={false}
        onCloseFilterClick={this.props.onToggleFiltersSection}
        alwaysShowSelectedFilters={false}
        shouldShowClearButton={true}
      />
    )
  }

  renderFiltersWindowTitle() {
    const { translate, activeFiltersCount } = this.props
    if (activeFiltersCount > 0) {
      return translate("filters.title_applied", { count: activeFiltersCount })
    }
    return translate("filters.title")
  }

  renderFiltersWindow() {
    const { isMobile, onToggleFiltersSection, renderAsModal } = this.props

    if (renderAsModal || isMobile) {
      return (
        <Modal onClose={onToggleFiltersSection} title={this.renderFiltersWindowTitle()}>
          {this.renderFilters()}
        </Modal>
      )
    }
    return this.renderFilters()
  }

  render() {
    const { isMobile, isShowingFiltersSection, onToggleFiltersSection, activeFiltersCount } =
      this.props

    return (
      <div className="filters__container">
        <FilterHeader
          isMobile={isMobile}
          isShowingFiltersSection={isShowingFiltersSection}
          onToggleFiltersSection={onToggleFiltersSection}
          activeFiltersCount={activeFiltersCount}
          translate={this.props.translate}
        />
        {isShowingFiltersSection && (
          <div className="filters__window">{this.renderFiltersWindow()}</div>
        )}
      </div>
    )
  }
}

export { FiltersView as UnwrappedFiltersView }
export default withI18n(FiltersView)
