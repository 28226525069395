import React, { Fragment, useState } from "react"
import SaveModal from "../saveModal"
import PropTypes from "prop-types"
import withI18n from "@geome/react-next/lib/withI18n"
import connectToFlux from "@geome/react-next/lib/connectToFlux"

const SaveRouteButton = ({ translate, loggedIn }) => {
  const [showingModal, toggleModal] = useState(false)
  if (!loggedIn) return null

  return (
    <Fragment>
      <button className="saved-routes__save-button" onClick={() => toggleModal(true)}>
        {translate("saved_routes.save_button.text")}
        <svg
          className="saved-routes__save-button-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          viewBox="0 0 24 24"
        >
          <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
        </svg>
      </button>
      {showingModal && <SaveModal onClose={() => toggleModal(false)} />}
    </Fragment>
  )
}

SaveRouteButton.propTypes = {
  translate: PropTypes.func,
  loggedIn: PropTypes.bool,
}

const queries = {
  loggedIn: "config.authentication.logged_in",
}

export { SaveRouteButton as UnwrappedSaveRouteButton }
export default connectToFlux(queries, {})(withI18n(SaveRouteButton))
