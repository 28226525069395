import { Command } from "@geome/flux"

class SwitchWaypoints extends Command {
  switchWaypoints(waypoints, name) {
    const waypoint = waypoints.find((w) => w.name === name)
    const waypointIndex = waypoints.indexOf(waypoint)
    waypoints[waypointIndex] = waypoints[waypointIndex + 1]
    waypoints[waypointIndex + 1] = waypoint
    return waypoints
  }

  execute(name) {
    const waypoints = this.queryCloned("directions.waypoints")
    if (waypoints) {
      this.dispatch({
        type: this.actionTypes.DIRECTIONS_WAYPOINTS_CHANGED,
        payload: this.switchWaypoints(waypoints, name),
      })
      this.dispatch({
        type: this.actionTypes.DIRECTIONS_ITINERARY_CHANGED,
        payload: this.query("directions.itinerary"),
      })
    }
  }
}

export default SwitchWaypoints
