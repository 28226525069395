import React from "react"
import SearchBox from "../searchBox"
import Filters from "../filters"

class Search extends React.Component {
  render() {
    return (
      <div className="search__container">
        <SearchBox />
        <Filters />
      </div>
    )
  }
}

export default Search
