import React, { useEffect } from "react"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import Modal from "../../shared/modal"
import PropTypes from "prop-types"
import withI18n from "@geome/react-next/lib/withI18n"
import CloseIcon from "../../../assets/icons/close"

const ListModal = ({
  onClose,
  translate,
  requestRoutes,
  savedRoutes,
  loadDirections,
  deleteRoute,
}) => {
  useEffect(() => requestRoutes(), [])
  return (
    <Modal
      windowClassName="saved-routes__list-modal"
      onClose={onClose}
      title={translate("saved_routes.title")}
    >
      <div className="saved-routes__list-modal-list">
        {savedRoutes.map((savedRoute) => (
          <div key={savedRoute.id} className="saved-routes__list-modal-item">
            <button
              className="saved-routes__list-modal-item-load-button"
              onClick={() => {
                loadDirections(savedRoute.route)
                onClose()
              }}
            >
              {savedRoute.name}
            </button>
            <button
              className="saved-routes__list-modal-item-delete-button"
              onClick={() => deleteRoute(savedRoute.id)}
            >
              <CloseIcon />
            </button>
          </div>
        ))}
      </div>
    </Modal>
  )
}

ListModal.propTypes = {
  translate: PropTypes.func,
  onClose: PropTypes.func,
  requestRoutes: PropTypes.func,
  loadDirections: PropTypes.func,
  deleteRoute: PropTypes.func,
  savedRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.any.isRequired,
      route: PropTypes.object.isRequired,
    })
  ),
}

const commands = {
  requestRoutes: "directions.requestRoutes",
  deleteRoute: "directions.deleteRoute",
  loadDirections: "directions.load",
}

const queries = {
  savedRoutes: "directions.savedRoutes",
}

export { ListModal as UnwrappedListModal }
export default withI18n(connectToFlux(queries, commands)(ListModal))
