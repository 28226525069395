import React from "react"
import { AralLogo, AralPulseLogo, BPLogo, AmocoLogo, MarkerShadow, TruckIcon } from "../images"
import PropTypes from "prop-types"

const styleForBrands = {
  "ARAL Tankstelle": {
    stroke: "#0064cc",
    fill: "#FFF",
    logo: AralLogo,
  },
  "Aral pulse Ladestation": {
    stroke: "#000096",
    fill: "#000096",
    logo: AralPulseLogo,
  },
  AR: {
    stroke: "#0064cc",
    fill: "#FFF",
    logo: AralLogo,
  },
  BP: {
    stroke: "#009200",
    fill: "#FFF",
    logo: BPLogo,
  },
  AM: {
    stroke: "#0064cc",
    fill: "#FFF",
    logo: AmocoLogo,
  },
}

const getStyling = (siteBrand, channelOfTrade) => {
  const base = styleForBrands[siteBrand] || styleForBrands.BP
  if (channelOfTrade === "UNCO") return { ...base, logo: TruckIcon }
  return base
}

const Marker = ({ site_brand: siteBrand, channel_of_trade: channelOfTrade, isNonMatching }) => {
  const styling = getStyling(siteBrand, channelOfTrade)

  return (
    <svg
      width="43"
      height="78"
      viewBox="0 -2 43 78"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <image
        className="marker__shadow"
        x="0"
        y="50"
        width="41"
        height="27"
        xlinkHref={MarkerShadow}
      />
      <g filter={isNonMatching ? "url(#marker-greyscale-filter)" : ""}>
        <path
          d="M20.73,0.098 C10.163,0.098 0.594,8.76 0.594,19.448 C0.594,30.132 9.324,42.888 20.728,61.184 C32.132,42.889 40.863,30.132 40.863,19.447 C40.863,8.76 31.296,0.098 20.728,0.098 L20.73,0.098 Z"
          stroke={styling.stroke}
          fill={isNonMatching ? "#CCC" : styling.fill}
          fillRule="nonzero"
        />
        <image
          className="marker__brand-icon"
          x="7.2285"
          y="5"
          width="27"
          height="27"
          xlinkHref={styling.logo}
        />
      </g>
    </svg>
  )
}

Marker.propTypes = {
  site_brand: PropTypes.oneOf(["BP", "ARAL Tankstelle", "Aral pulse Ladestation", "AM"]),
  isNonMatching: PropTypes.bool,
}

export default Marker
