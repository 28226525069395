export default {
  distance: {
    value: "{distance, number, distance} {unit}",
    onlyUnit: "{unit}",
    unit: {
      miles: "km"
    }
  },
  search: {
    input_placeholder: "Search for a location...",
    suggestions: "Suggestions"
  },
  info_window: {
    directions: {
      button: "Get Directions"
    },
    website_button: "Voir prix des carburants",
    services: {
      title: "Installations"
    },
    fuel_prices: {
      title: "Fuel Prices"
    },
    opening: {
      title: "Opening Hours"
    },
    opening_hours: {
      open_twentyfourhours: "Open 24 hours",
      mon: "Lundi",
      tue: "Mardi",
      wed: "Mercredi",
      thu: "Jeudi",
      fri: "Vendredi",
      sat: "Samedi",
      sun: "Dimanche",
      title: "Opening hours",
      period: "{start, time, short} – {end, time, short}"
    }
  },
  locations_list: {
    title: "Nearby Stations"
  },
  traffic_button: {
    shown: "Trafic activé",
    hidden: "Trafic desactivé"
  },
  watchlist: {
    add: "Add to Watchlist",
    remove: "Remove from Watchlist"
  },
  saved_routes: {
    title: "Saved Routes",
    list: {
      title: "Saved Routes"
    },
    save_modal: {
      title: "Save Route",
      placeholder: "Route Name",
      error: "An error has occurred",
      cancel: "Cancel",
      save: "Save Route"
    },
    save_button: {
      text: "Save Route"
    }
  },
  geolocation: {
    tooltip: {
      text: "Your current location",
      error: "Location not available"
    }
  },
  opening_hours: {
    twenty_four_hour: "Ouvert 24/24",
    open: "Open Now",
    closed: "Closed Now"
  },
  directions: {
    route: {
      avoid_highways: "Éviter les autoroutes",
      avoid_tolls: "Éviter les péages",
      show_stations_on_route: "Show stations along route",
      find: "Get directions",
      new: "Calculateur d'itinéraire",
      title: "Options de trajet",
      edit: "Edit Route",
      title_corridor: "Rechercher la distance des stations service sur le trajet"
    },
    placeholder: {
      destination: "To",
      origin: "From",
      waypoint: "Via"
    },
    help: {
      remove: "Remove",
      switch_waypoints: "Switch from and to",
      add_waypoint: "Ajouter une destination"
    }
  },
  itinerary: {
    title: "Ajouter une destination",
    from: "From",
    to: "To",
    totals: {
      full: "Total {durationText} ({distanceText})",
      distance: "{distance, number, distance} {unit}",
      duration:
        "{weeks, plural, =0 {} =1 {# wk} other {# wks}} {days, plural, =0 {} =1 {# day} other {# days}} {hours, plural, =0 {} =1 {# hr} other {# hrs}} {minutes, plural, =0 {} =1 {# min} other {# mins}}"
    }
  },
  filters: {
    title: "Filtres",
    clear: "Clear Filters",
    title_applied: "Filters ({count} applied)",
    popular: {
      title: "Popular Filters"
    },
    food: {
      title: "Food"
    },
    car_wash: {
      title: "Car wash"
    },
    facilities: {
      title: "Installations"
    },
    products: {
      title: "Carburant"
    }
  },
  general_buttons: {
    back: "Back"
  },
  facilities: {
    aral_store: "Store",
    bp_connect_store: "Boutique BP Connect",
    bunker_site: "Bunker",
    motorway_site: "station-service autoroutière",
    accepts_routex_cards: "Accepte Routex",
    accepts_toll_payments: "Accepte les paiements de frais",
    reminder_service: "Service de rappel",
    car_wash: "CarWash voiture",
    cash_point: "Distributeur de billets",
    disabled_facilities: "Installations pour personnes handicapées",
    high_speed_pump: "Pompe à haut débit",
    lorry_and_truck_terminal: "Terminal camions",
    lotto: "Loto",
    toilet: "Toilettes",
    pump_rewards: "Pump Rewards",
    truck_parking: "Parking pour camions",
    truck_wash: "installation de lavage pour camions",
    truck_suitable_site: "Site adapté aux camions",
    wifi: "WLAN",
    loyalty: "PAYBACK",
    car_rental: "Car Rental",
    ruc: "Road User Charges",
    trailers: "Trailer Hire",
    bonus_club: "BP Bonusclub",
    go_box: "Go Box",
    truck_only: "Truckstop",
    super_wash: "Aral SuperWash",
    car_wash_plant: "Laverie automobile",
    super_boxes: "SB Waschplätze",
    vda: "VDA Konform Siegel",
    jet_wash: "CarWash avec jet préssurisé",
    special_car_wash: "installation de lavage spéciale",
    open_24_hours: "Ouvert 24/24",
    automatic_site: "BP Express",
    swappa_bottle: "Swappa Bottle LPG",
    biker_point: "Bikerpoint",
    tyre_service: "Tyre Service",
    uta: "UTA",
    credit_card_maestro: "Credit Card & Maestro",
    dkv: "DKV",
    bike_friendly: "Bike Friendly",
    rewe_to_go: "Rewe to go",
    bakery_bistro: "Boulangerie Bistro",
    bistro: "Bistro",
    ms_simply_food: "Nourriture M&S",
    petit_bistro: "Petit Bistro",
    restaurant: "Restaurant",
    shop: "shop",
    shop_open_24_hours: "shop 24/24",
    supermarket: "Supermarché",
    tobacco: "Tabac",
    alcohol: "Alcool",
    wild_bean_cafe: "Café Wild Bean",
    merkur: "MERKUR Inside",
    bottle_gas: "BP Bottle Gas",
    mobile_enabled: "mobile_enabled"
  },
  products: {
    ad_blue_cannister: "AdBlue Canister",
    ad_blue_pump: "Pompe pour AdBlue",
    aa_smart_fuel: "AA Smartfuel",
    lpg: "GPL",
    auto_gas: "GPL",
    natural_gas: "Gaz naturel",
    red_diesel: "Diesel rouge",
    truck_diesel: "Diesel camions",
    ultimate: "Ultimate",
    ultimate_102: "Ultimate 102",
    ultimate_diesel: "Ultimate Diesel",
    ultimate_unleaded: "Ultimate sans plomb",
    super_e5: "Aral Super E5",
    super_98: "Aral Super 98",
    ad_blue: "AdBlue",
    ultimate_98: "Ultimate 98",
    unleaded_91: "91",
    unleaded_95: "95",
    regular_diesel: "Diesel",
    ultimate_super: "BP Ultimate Super",
    diesel_add: "Diesel with additives",
    unleaded_91_add: "Petrol/Gas ROZ 91  with additives",
    unleaded_95_add: "Petrol/Gas ROZ 95  with additives",
    unleaded_98: "Petrol/Gas ROZ 98",
    diesel_frost: "Diesel with better frost protection",
    futura_fuel_oil: "Fuel Oil / Heating Oil - Branded: Futura",
    euro_95: "Petrol/Gas ROZ 95 with 10% Ethanol",
    fuel_oil: "Fuel Oil / Heating Oil",
    lotto: "Loto",
    bistro: "Bistro",
    petit_bistro: "Petit Bistro",
    restaurant: "Restaurant",
    shop: "shop",
    tobacco: "Tabac",
    alcohol: "Alcool"
  }
}
