import React from "react"
import PropTypes from "prop-types"

const CompletionIcon = ({ className }) => {
  return (
    <svg
      className={className || ""}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>7636763B-4E7D-40D6-94F0-134331C33A52</title>
      <path
        d="M7 1C3.683 1 1 3.66 1 6.95 1 11.412 7 18 7 18s6-6.588 6-11.05C13 3.66 10.317 1 7 1zm0 8a2 2 0 1 1 .001-4.001A2 2 0 0 1 7 9z"
        stroke="#A5A5A5"
        fill="none"
      />
    </svg>
  )
}
CompletionIcon.propTypes = {
  className: PropTypes.string
}
export default CompletionIcon
