import React from "react"
import SearchBox from "@geome/react-components/lib/components/searchBox"
import AralGeolocationButton from "../../aralGeolocationButton"
import PropTypes from "prop-types"
import SearchIcon from "../../../assets/icons/search"
import CompletionIcon from "../../../assets/icons/completions"
import DirectionsIcon from "../../../assets/icons/directions"
import { GoogleLicence } from "../../../assets/images"
import withI18n from "@geome/react-next/lib/withI18n"

class SearchBoxView extends React.Component {
  static propTypes = {
    contextName: PropTypes.string,
    translate: PropTypes.func.isRequired,
    renderToRight: PropTypes.func,
    onClick: PropTypes.func,
    routeToDirections: PropTypes.func,
    useDirectionsIcon: PropTypes.bool,
    useSearchIcon: PropTypes.bool,
    renderToLeft: PropTypes.func,
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    useDirectionsIcon: true,
    useSearchIcon: true,
    renderToLeft: () => <AralGeolocationButton />,
  }

  renderDirectionsButton() {
    if (!this.props.useDirectionsIcon) return null
    return (
      <div onClick={() => this.props.routeToDirections()}>
        <DirectionsIcon className="search__directions-icon" />
      </div>
    )
  }

  renderSearchButton(query) {
    return (
      <div className="search__render-to-right">
        {!query && this.props.useSearchIcon && <SearchIcon className="search-icon" />}
        {this.renderDirectionsButton()}
      </div>
    )
  }

  renderCompletionList(props) {
    return (
      <div {...props}>
        <h3 className="search-box__completion-list-title">
          {this.props.translate("search.suggestions")}
        </h3>
        {props.children}
        <div className="u-flex-row-reverse u-flex-justify-end">
          <img className="search-box__completion_list-license" src={GoogleLicence} />
        </div>
      </div>
    )
  }

  renderCompletionListItem(item) {
    return (
      <div className="u-flex-row u-flex-center">
        <div className="search-box__completion_list-item-icon">
          <CompletionIcon />
        </div>
        <div className="search-box__completion_list-item-text">{item.value}</div>
      </div>
    )
  }

  renderToRight(query) {
    if (this.props.renderToRight) return this.props.renderToRight(query)
    return this.renderSearchButton(query)
  }

  render() {
    const { translate, renderToLeft, placeholder } = this.props

    return (
      <SearchBox
        {...this.props}
        placeholder={placeholder || translate("search.input_placeholder")}
        renderToRight={({ query }) => this.renderToRight(query)}
        renderToLeft={renderToLeft}
        renderCompletionList={({ props }) => this.renderCompletionList(props)}
        renderCompletionListItem={({ props: completionItemProps, item }) => (
          <button {...completionItemProps}>{this.renderCompletionListItem(item)}</button>
        )}
        positionCompletionsToInputWrapper
      />
    )
  }
}

export default withI18n(SearchBoxView)
