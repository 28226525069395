import React from "react"
import LocationsListContainer from "@geome/react-components/lib/containers/locationsList"
import { isClustered } from "@geome/react-components/lib/components/utils/location"
import PropTypes from "prop-types"
import LocationsListView from "./view"
import connectToFlux from "@geome/react-next/lib/connectToFlux"

const LocationsList = (props) => {
  const { nearestLocations } = props
  if (nearestLocations.length === 0) return null
  return (
    <LocationsListContainer>
      {({ locations, ...viewProps }) => {
        if (locations.some((l) => isClustered(l))) return null
        return <LocationsListView {...props} {...viewProps} locations={nearestLocations} />
      }}
    </LocationsListContainer>
  )
}

LocationsList.propTypes = {
  dialogsShouldBeDisabled: PropTypes.bool,
  nearestLocations: PropTypes.array.isRequired,
  locationInfo: PropTypes.object.isRequired,
}

LocationsList.defaultProps = {
  nearestLocations: [],
}

const queries = {
  nearestLocations: "locations.views.radial",
  locationInfo: "config.locationInfo",
}

export default connectToFlux(queries, {})(LocationsList)
