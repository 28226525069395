import React from "react"
import PropTypes from "prop-types"
import SwitchIcon from "../../../../assets/icons/switch"
import AddIcon from "../../../../assets/icons/add"
import withI18n from "@geome/react-next/lib/withI18n"
import Tooltip from "@geome/react-components/lib/components/tooltip"

class WaypointDivider extends React.Component {
  static propTypes = {
    onWaypointAdd: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    onSwitchWaypoints: PropTypes.func.isRequired,
    waypointsLimitReached: PropTypes.bool,
    index: PropTypes.number,
    name: PropTypes.string,
  }

  handleWaypointAdded() {
    this.props.onWaypointAdd(this.props.index)
  }

  handleSwitchWaypoints() {
    this.props.onSwitchWaypoints(this.props.name)
  }

  renderAddWaypointButton() {
    const { translate, waypointsLimitReached } = this.props
    return (
      <button
        className={`directions__add-waypoint-button ${
          waypointsLimitReached ? "directions__add-waypoint-button--disabled" : ""
        }`}
        onClick={() => this.handleWaypointAdded()}
        disabled={waypointsLimitReached}
      >
        <span>{translate("directions.help.add_waypoint")}</span>
        <AddIcon
          className={`directions__add-waypoint-button-icon ${
            waypointsLimitReached ? "directions__add-waypoint-button-icon--disabled" : ""
          }`}
          isDisabled={waypointsLimitReached}
        />
      </button>
    )
  }

  renderSwitchWaypointsButton() {
    return (
      <Tooltip text={this.props.translate("directions.help.switch_waypoints")}>
        {(props) => (
          <button
            {...props}
            className={`directions__switch-waypoints-button directions__switch-waypoints-button-${this.props.index}`}
            id="directions-switch-waypoints-button"
            onClick={() => this.handleSwitchWaypoints()}
          >
            <SwitchIcon />
          </button>
        )}
      </Tooltip>
    )
  }

  render() {
    return (
      <div className="directions__waypoint-divider" ref={(p) => (this.panel = p)}>
        {this.renderAddWaypointButton()}
        {this.renderSwitchWaypointsButton()}
      </div>
    )
  }
}

export default withI18n(WaypointDivider)
