import React from "react"
import PropTypes from "prop-types"
import withI18n from "@geome/react-next/lib/withI18n"
import DirectionsComponent from "@geome/react-components/lib/components/directions"
import CloseIcon from "../../../../assets/icons/close"
import InputWrapper from "./inputWrapper"
import RouteOptions from "./options"

class Route extends React.Component {
  static propTypes = {
    directionsOptions: PropTypes.object,
    onChangeDirectionsOptions: PropTypes.func,
    onGeolocationSelect: PropTypes.func,
    onWaypointRemove: PropTypes.func,
    onExitDirections: PropTypes.func,
    onWaypointAdd: PropTypes.func,
    onSwitchWaypoints: PropTypes.func,
    onToggleRouteSettings: PropTypes.func,
    translate: PropTypes.func,
    geolocationStatus: PropTypes.string,
    isGeolocationAvailable: PropTypes.bool,
    isShowingRouteOptions: PropTypes.bool,
    waypointsLimitReached: PropTypes.bool,
    isMobile: PropTypes.bool,
  }

  renderWaypoints() {
    return (
      <div className="directions__container-route-waypoints">
        <DirectionsComponent
          {...this.props}
          placeholders={{
            origin: this.props.translate("directions.placeholder.origin"),
            destination: this.props.translate("directions.placeholder.destination"),
            waypoint: this.props.translate("directions.placeholder.waypoint"),
          }}
          renderContainer={({ props: directionsWrapperProps }) => (
            <div {...directionsWrapperProps} />
          )}
          renderInputWrapper={(props) => (
            <InputWrapper
              {...props}
              geolocationStatus={this.props.geolocationStatus}
              isGeolocationAvailable={this.props.isGeolocationAvailable}
              onGeolocationSelect={this.props.onGeolocationSelect}
              onWaypointAdd={this.props.onWaypointAdd}
              onWaypointRemove={this.props.onWaypointRemove}
              onSwitchWaypoints={this.props.onSwitchWaypoints}
              waypointsLimitReached={this.props.waypointsLimitReached}
            />
          )}
        />
      </div>
    )
  }

  renderHeader() {
    return (
      <div className="directions__container-route-header">
        <div
          className="directions__container-route-header-close-button"
          onClick={this.props.onExitDirections}
        >
          <CloseIcon />
        </div>
      </div>
    )
  }

  renderOptions() {
    const {
      translate,
      directionsOptions,
      onChangeDirectionsOptions,
      onToggleRouteSettings,
      isShowingRouteOptions,
      isMobile,
    } = this.props

    return (
      <RouteOptions
        translate={translate}
        directionsOptions={directionsOptions}
        onChangeDirectionsOptions={onChangeDirectionsOptions}
        onToggleRouteSettings={onToggleRouteSettings}
        isShowingRouteOptions={isShowingRouteOptions}
        isMobile={isMobile}
      />
    )
  }

  render() {
    return (
      <div
        className={`directions__container-route ${
          this.props.isShowingRouteOptions ? "directions__container-route--options-open" : ""
        }`}
      >
        {this.renderHeader()}
        {this.renderWaypoints()}
        {this.renderOptions()}
      </div>
    )
  }
}

export default withI18n(Route)
