import React from "react"
import ItineraryContainer from "@geome/react-components/lib/containers/itinerary"
import ItineraryView from "@geome/react-components/lib/components/itinerary"
import withI18n from "@geome/react-next/lib/withI18n"
import PropTypes from "prop-types"
import SaveRouteButton from "../savedRoutes/saveRouteButton"

const Itinerary = (props) => {
  return (
    <ItineraryContainer>
      {(viewProps) => (
        <ItineraryView
          {...props}
          {...viewProps}
          renderTotals={({ content }) => (
            <div className="u-flex-row">
              {content}
              <SaveRouteButton />
            </div>
          )}
        />
      )}
    </ItineraryContainer>
  )
}

Itinerary.propTypes = {
  dialogsShouldBeDisabled: PropTypes.bool,
  translate: PropTypes.func,
}

export default withI18n(Itinerary)
