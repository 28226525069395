import LayoutStoreBase from "@geome/sdk/lib/app/store/layout"

class Layout extends LayoutStoreBase {
  static initClass() {
    this.addActions({
      LAYOUT_DIRECTIONS_STEP_SET(action) {
        this.set("directionsStep", action.payload)
      },
    })
  }
  initialize() {
    super.initialize(...arguments)
    this.set("directionsStep", null)
  }
}

Layout.initClass()

export default Layout
