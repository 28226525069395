import React from "react"
import PropTypes from "prop-types"
import InfoWindowView from "./view"
import { getLocationWithFilteredProperties } from "../../utils/locations"
import connectToFlux from "@geome/react-next/lib/connectToFlux"

class InfoWindow extends React.Component {
  static propTypes = {
    unselectLocation: PropTypes.func,
    restoreBookmark: PropTypes.func,
    selectLocationAsWaypoint: PropTypes.func,
    selectedLocation: PropTypes.object,
    dialogsShouldBeDisabled: PropTypes.bool,
    locationInfo: PropTypes.object,
  }

  componentDidMount() {
    const { selectedLocation, filterConstraints } = this.props
    const filterThings = Object.fromEntries(
      Object.entries(filterConstraints?.withAll || {}).map(([key, value]) => [
        `filter_${key}`,
        value.join(","),
      ])
    )

    window.parent.postMessage(
      {
        event: "locationlistdetailview",
        stationName: selectedLocation.name,
        stationCity: selectedLocation.city,
        ...filterThings,
      },
      "*"
    )
  }

  handleDismissal() {
    this.props.unselectLocation()
    this.props.restoreBookmark()
  }

  handleSelectLocationAsWaypoint() {
    const { selectedLocation, selectLocationAsWaypoint, unselectLocation, enqueueAnalytic } =
      this.props

    window.parent.postMessage(
      {
        event: "stationfinderview",
        ctaName: "Route anzeigen",
        stationName: selectedLocation.name,
        stationCity: selectedLocation.city,
      },
      "*"
    )

    selectLocationAsWaypoint(selectedLocation)
    enqueueAnalytic({
      category: "station finder",
      label: selectedLocation.name,
      action: "click directions",
    })
    unselectLocation()
  }

  render() {
    return (
      <InfoWindowView
        enqueueAnalytic={this.props.enqueueAnalytic}
        dialogsShouldBeDisabled={this.props.dialogsShouldBeDisabled}
        onDismissal={() => this.handleDismissal()}
        onSelectLocationAsWaypoint={() => this.handleSelectLocationAsWaypoint()}
        selectedLocation={getLocationWithFilteredProperties(
          this.props.locationInfo,
          this.props.selectedLocation
        )}
      />
    )
  }
}

const commands = {
  unselectLocation: "locations.unselect",
  restoreBookmark: "routing.restoreBookmark",
  selectLocationAsWaypoint: "directions.selectLocationAsWaypoint",
  enqueueAnalytic: "analytics.enqueue",
}

const queries = {
  selectedLocation: "locations.selected",
  locationInfo: "config.locationInfo",
  facilitiesSortOrder: "config.facilitiesSortOrder",
  filterConstraints: "filters.constraints",
}

export { InfoWindow as UnwrappedInfoWindow }
export default connectToFlux(queries, commands)(InfoWindow)
