import React from "react"

const SvgDefs = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className="svg-defs"
  >
    <defs>
      <filter id="marker-greyscale-filter">
        <feColorMatrix type="saturate" values="0" />
      </filter>
    </defs>
  </svg>
)

export default SvgDefs
