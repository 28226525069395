import Command from "@geome/flux/lib/command"
import Promise from "bluebird"

class LoadDirections extends Command {
  constructor(options) {
    super(...arguments)
    this.placeSearchUtil = options.placeSearchUtil
  }

  execute(params) {
    const { waypoints, options } = params
    const metadata = this.query("resources.metadata")
    this.completeWaypoints(waypoints, metadata).then((completedWaypoints) => {
      this.dispatch({
        type: this.actionTypes.DIRECTIONS_WAYPOINTS_SET_ALL,
        payload: completedWaypoints,
      })
      this.dispatchOptions(options)
    })
  }

  dispatchOptions(options) {
    if (options) {
      this.dispatch({
        type: this.actionTypes.DIRECTIONS_OPTIONS_INITIALIZED,
        payload: options,
      })
    }
  }

  completeWaypoints(waypoints, metadata) {
    return Promise.all(
      waypoints.map((w) => {
        if (!w.origin) {
          return this.placeSearchUtil.findFromQuery({ query: w.description }, metadata)
        } else {
          return Promise.resolve(w)
        }
      })
    )
  }
}

export default LoadDirections
