import React from "react"
import SearchContainer from "@geome/react-components/lib/containers/search"
import SearchBoxView from "./view"
import PropTypes from "prop-types"
import connectToFlux from "@geome/react-next/lib/connectToFlux"

class SearchBox extends React.Component {
  static propTypes = {
    routingSet: PropTypes.func,
  }

  handleRouteToDirections() {
    this.props.routingSet("directions")
  }

  render() {
    return (
      <SearchContainer>
        {(props) => (
          <SearchBoxView {...props} routeToDirections={() => this.handleRouteToDirections()} />
        )}
      </SearchContainer>
    )
  }
}

const commands = {
  routingSet: "routing.set",
}

export default connectToFlux({}, commands)(SearchBox)
