import React from "react"
import PropTypes from "prop-types"

class OpeningHoursEntry extends React.Component {
  static propTypes = {
    days: PropTypes.string.isRequired,
    times: PropTypes.array.isRequired,
  }

  timePeriodFor(entry) {
    return (
      <div key={this.key(entry)} className="opening-hours-entry__time">
        {entry}
      </div>
    )
  }

  key(entry) {
    return `openingHoursEntry_${this.props.days}_${entry}`
  }

  render() {
    const { times, days } = this.props
    return (
      <div className="opening-hours-entry">
        <div className="opening-hours-entry__days">{days}</div>
        <div className="opening-hours-entry__times">
          {times.map((entry) => this.timePeriodFor(entry))}
        </div>
      </div>
    )
  }
}

export default OpeningHoursEntry
