import React from "react"
import PropTypes from "prop-types"

const GeolocationIcon = ({ className }) => {
  return (
    <svg className={className || ""} xmlns="http://www.w3.org/2000/svg" width="18" height="24">
      <path
        fill="#0064CC"
        d="M9 0C4.03 0 0 4.029 0 8.997 0 14.043 3.938 17.493 9 22c5.063-4.507 9-7.957 9-13.003C18 4.03 13.97 0 9 0zm.9 16.438v-3.605H8.1v3.605c-3.256-.416-5.831-3.04-6.238-6.355H5.4V8.25H1.862C2.27 4.934 4.845 2.31 8.1 1.897V5.5h1.8V1.897c3.255.414 5.831 3.037 6.238 6.353H12.6v1.833h3.538c-.407 3.316-2.983 5.94-6.238 6.355z"
      />
    </svg>
  )
}
GeolocationIcon.propTypes = {
  className: PropTypes.string
}
export default GeolocationIcon
