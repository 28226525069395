const ActionTypes = [
  "LAYOUT_DIRECTIONS_STEP_SET",
  "WATCHLIST_TOGGLED",
  "WATCHLIST_LOADED",
  "DIRECTIONS_WAYPOINTS_LIMIT_SET",
  "DIRECTIONS_ROUTE_SAVED",
  "DIRECTIONS_ROUTES_LOADED",
  "DIRECTIONS_SAVED_ROUTE_DELETED",
  "DIRECTIONS_SAVE_ROUTE_STATUS_SET"
]

export default ActionTypes
