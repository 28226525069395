import React from "react"
import PropTypes from "prop-types"
import withI18n from "@geome/react-next/lib/withI18n"
import DirectionsButton from "../directionsButton"
import WatchlistButton from "../watchlistButton"
import OpenStatus from "../openStatus/index"
import { BPLogo, AralLogo, AralPulseLogoFull, AmocoLogo } from "../../assets/images"
import OpeningHours from "../openingHours"

class InfoWindowView extends React.Component {
  static propTypes = {
    onDismissal: PropTypes.func.isRequired,
    selectedLocation: PropTypes.object,
    onSelectLocationAsWaypoint: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    enqueueAnalytic: PropTypes.func.isRequired,
  }

  renderTopLevelInfo() {
    const { name, address, city, telephone, postcode } = this.props.selectedLocation
    return (
      <div className="u-flex-justify u-flex-row">
        <div className="info-window__address-details group-padding-y">
          <div className="info-window__name">{name}</div>
          <div className="info-window__address">{address}</div>
          <div className="info-window__city">{postcode}</div>
          <div className="info-window__postcode">{city}</div>
          {telephone && <div className="info-window__telephone">{telephone}</div>}
        </div>
        <div className="u-flex-column u-flex-end">
          <DirectionsButton
            onClick={this.props.onSelectLocationAsWaypoint}
            translate={this.props.translate}
          />
          {this.renderOpenStatus()}
        </div>
      </div>
    )
  }

  renderWebsiteButton() {
    const { enqueueAnalytic, selectedLocation } = this.props
    const { website, name } = selectedLocation
    if (!website) return null
    return (
      <a
        href={website}
        target="_blank"
        rel="noopener noreferrer"
        className="info-window__website-button"
        onClick={() => {
          window.parent.postMessage(
            {
              event: "locationlistinteraction",
              ctaName: "Zur Tankstellen-Webseite",
              stationName: selectedLocation.name,
              stationCity: selectedLocation.city,
              targetUrl: website,
            },
            "*"
          )

          enqueueAnalytic({
            category: "station finder",
            label: name,
            action: "click station site",
          })
        }}
      >
        {this.props.translate("info_window.website_button")}
      </a>
    )
  }

  renderWatchListButton() {
    return (
      <WatchlistButton
        className="info-window__item-button info-window__item-button--watchlist"
        location={this.props.selectedLocation}
      />
    )
  }

  renderOpeningHoursInfo() {
    const openingData = this.props.selectedLocation.opening_hours
    if (!openingData || openingData.length === 0) return null

    return (
      <div className="info-window__section info-window__section-opening-hours">
        <h2 className="info-window__section-header">
          {this.props.translate("info_window.opening.title")}
        </h2>
        <OpeningHours translate={this.props.translate} openingHours={openingData} />
      </div>
    )
  }

  renderServicesInfo() {
    const { facilities } = this.props.selectedLocation
    if (facilities.length == 0) return null
    const facilitiesText = facilities.map((f) => this.props.translate(`facilities.${f}`))
    const split = Math.ceil(facilitiesText.length / 2)
    const columns = [
      facilitiesText.filter((f, i) => i < split),
      facilitiesText.filter((f, i) => i >= split),
    ]

    return (
      <div className="info-window__section">
        <h2 className="info-window__section-header">
          {this.props.translate("info_window.services.title")}
        </h2>
        <div className="u-flex-row u-flex-justify">
          <div className="info-window__column info-window__column--split">
            {this.renderServicesColumnItems(columns[0])}
          </div>
          <div className="info-window__column-border" />
          <div className="info-window__column info-window__column--split">
            {this.renderServicesColumnItems(columns[1])}
          </div>
        </div>
      </div>
    )
  }

  renderServicesColumnItems(column) {
    if (!column) return null
    return column.map((service) => <div key={service}>{service}</div>)
  }

  renderFuelPricesInfo() {
    const { fuel_pricing } = this.props.selectedLocation
    if (!fuel_pricing?.prices) return null
    const lastUpdatedOn = fuel_pricing.last_update
    return (
      <div className="info-window__section">
        <h2 className="info-window__section-header info-window__fuel-prices-header">
          {this.props.translate("info_window.fuel_prices.title")}
        </h2>
        <div className="info-window__fuel-prices-disclaimer">
          <div>{this.props.translate("info_window.fuel_prices.timestamp", { lastUpdatedOn })}</div>
          <div>{this.props.translate("info_window.fuel_prices.disclaimer")}</div>
        </div>
        {Object.entries(fuel_pricing.prices).map(([fuelName, price]) => (
          <div key={fuelName} className="info-window__fuel-prices">
            <p className="info-window__fuel-name">{fuelName}</p>
            <p className="info-window__fuel-price" dangerouslySetInnerHTML={{ __html: price }}></p>
          </div>
        ))}
      </div>
    )
  }

  getBrandLogo() {
    const { site_brand } = this.props.selectedLocation
    switch (site_brand) {
      case "ARAL Tankstelle":
      case "AR":
        return AralLogo
      case "Aral pulse Ladestation":
        return AralPulseLogoFull
      case "AM":
        return AmocoLogo
      default:
        return BPLogo
    }
  }

  renderOpenStatus() {
    const { open_status } = this.props.selectedLocation
    return <OpenStatus openStatus={open_status} translate={this.props.translate} />
  }

  renderBackButton() {
    return (
      <div onClick={this.props.onDismissal} className="info-window__back-button">
        <div className="info-window__back-button-label">
          {this.props.translate("general_buttons.back")}
        </div>
      </div>
    )
  }

  render() {
    const { selectedLocation } = this.props
    if (!selectedLocation) return null
    return (
      <div className="info-window">
        {this.renderBackButton()}
        <img className="info-window__brand-logo" src={this.getBrandLogo()} alt="brand logo" />
        <div className="info-window__top-level-info">
          {this.renderTopLevelInfo()}
          <div className="u-flex-column">
            {this.renderWatchListButton()}
            {this.renderWebsiteButton()}
          </div>
        </div>
        {this.renderOpeningHoursInfo()}
        {this.renderServicesInfo()}
        {this.renderFuelPricesInfo()}
      </div>
    )
  }
}

export { InfoWindowView as UnwrappedInfoWindowView }
export default withI18n(InfoWindowView)
