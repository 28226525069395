import React from "react"
import PropTypes from "prop-types"
import DirectionsContainer from "@geome/react-components/lib/containers/directions"
import DirectionsView from "./view"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import { DIRECTIONS_STEPS } from "../../values/directionsSteps"

class Directions extends React.Component {
  static propTypes = {
    directionsClear: PropTypes.func,
    directionsSwitchWaypoints: PropTypes.func,
    directionsChangeOptions: PropTypes.func,
    layoutToggleWindow: PropTypes.func,
    geolocationSelect: PropTypes.func,
    setDirectionsStep: PropTypes.func,
    routingSet: PropTypes.func,
    isShowingRouteOptions: PropTypes.bool,
    directionsStep: PropTypes.oneOf([null, DIRECTIONS_STEPS.INPUT, DIRECTIONS_STEPS.VIEWING_ROUTE]),
    setWaypointPlace: PropTypes.func,
    selectedSearchLocation: PropTypes.object,
    waypoints: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    isMobile: PropTypes.bool,
  }

  componentDidMount() {
    this.prefillOrigin()
    this.props.setDirectionsStep(DIRECTIONS_STEPS.INPUT)
  }

  componentWillUnmount() {
    this.props.setDirectionsStep(null)
  }

  prefillOrigin() {
    const { waypoints, selectedSearchLocation } = this.props
    const [origin] = waypoints

    if (selectedSearchLocation && !origin?.place) {
      this.props.setWaypointPlace({ name: origin.name, place: selectedSearchLocation })
    }
  }

  handleExitDirections() {
    const { directionsClear, routingSet, isShowingRouteOptions } = this.props
    directionsClear()
    window.requestAnimationFrame(() => routingSet("search"))
    if (isShowingRouteOptions) this.handleToggleRouteSettings()
  }

  handleNewRoute() {
    this.props.directionsClear()
    this.props.setDirectionsStep(DIRECTIONS_STEPS.INPUT)
  }

  handleToggleRouteSettings() {
    this.props.layoutToggleWindow("routeOptions")
  }

  handleGeolocationSelect(waypointName) {
    this.props.geolocationSelect(`directions.${waypointName}`)
  }

  handleSwitchWaypoints(waypointName) {
    this.props.directionsSwitchWaypoints(waypointName)
  }

  getViewProps() {
    return {
      ...this.props,
      onToggleRouteSettings: () => this.handleToggleRouteSettings(),
      onChangeDirectionsOptions: (options) => this.props.directionsChangeOptions(options),
      onExitDirections: () => this.handleExitDirections(),
      onNewRoute: () => this.handleNewRoute(),
      onGeolocationSelect: (waypointName) => this.handleGeolocationSelect(waypointName),
      onSwitchWaypoints: (waypointName) => this.handleSwitchWaypoints(waypointName),
    }
  }
  render() {
    return (
      <DirectionsContainer>
        {(viewProps) => <DirectionsView {...viewProps} {...this.getViewProps()} />}
      </DirectionsContainer>
    )
  }
}

const queries = {
  isShowingRouteOptions: "layout.window.routeOptions.active",
  selectedSearchLocation: "search.selectedPlace",
  directionsOptions: "directions.options",
  distanceUnit: "i18n.intlData.formats.number.distance.unit",
  isGeolocationAvailable: "geolocation.isAvailable",
  directionsStep: "layout.directionsStep",
  waypointsLimitReached: "directions.waypointsLimitReached",
  geolocationStatus: "geolocation.status",
  waypoints: "directions.waypoints",
}

const commands = {
  setDirectionsStep: "layout.setDirectionsStep",
  layoutToggleWindow: "layout.toggleWindow",
  directionsChangeOptions: "directions.changeOptions",
  directionsClear: "directions.clear",
  directionsSwitchWaypoints: "directions.switchWaypoints",
  routingSet: "routing.set",
  geolocationSelect: "geolocation.select",
  setWaypointPlace: "directions.setWaypointPlace",
}

export default withContexts(EnvironmentContext)(connectToFlux(queries, commands)(Directions))
