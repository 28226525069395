import React, { Fragment } from "react"
import PropTypes from "prop-types"
import withI18n from "@geome/react-next/lib/withI18n"
import WatchlistButton from "../../watchlistButton"
import OpenStatus from "../../openStatus"

class LocationsListItem extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    translate: PropTypes.func,
    item: PropTypes.shape({
      watchlist_id: PropTypes.string,
      open_status: PropTypes.string,
      telephone: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      postcode: PropTypes.string,
      facilities: PropTypes.array,
    }),
    facilityDisplayCount: PropTypes.number,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    facilityDisplayCount: 6,
  }

  renderInfoLine(infoKey) {
    const infoLine = this.props.item[infoKey]
    if (!infoLine) return null
    return (
      <div className={`locations-list-item__info-item locations-list-item__info-item--${infoKey}`}>
        {infoLine}
      </div>
    )
  }

  renderMainInfo() {
    return (
      <Fragment>
        {this.renderInfoLine("name")}
        {this.renderInfoLine("address")}
        {this.renderInfoLine("postcode")}
        {this.renderInfoLine("city")}
        {this.renderInfoLine("country")}
        {this.renderInfoLine("telephone")}
      </Fragment>
    )
  }

  renderOpenStatus() {
    const { open_status } = this.props.item
    return (
      <Fragment>
        <OpenStatus openStatus={open_status} translate={this.props.translate} />
      </Fragment>
    )
  }

  renderExtraFacilities(count) {
    if (count === 0) return null
    return (
      <div className="locations-list__item-facilities-item locations-list__item-facilities-item--extra">{`+${count}`}</div>
    )
  }

  renderFacilities() {
    const { facilityDisplayCount } = this.props
    const { facilities } = this.props.item
    if (!facilities || facilities.length === 0 || facilityDisplayCount === 0) return null

    const facilitiesToDisplay = facilities.slice(0, facilityDisplayCount)
    const countOfHiddenFacilities = facilities.length - facilitiesToDisplay.length
    return (
      <Fragment>
        {facilitiesToDisplay.map((facility) => (
          <div className="locations-list__item-facilities-item" key={facility}>
            {this.props.translate(`facilities.${facility}`)}
          </div>
        ))}
        {this.renderExtraFacilities(countOfHiddenFacilities)}
      </Fragment>
    )
  }

  getNormalItemProps() {
    const { onClick, onMouseEnter, onMouseLeave } = this.props
    return {
      onClick,
      onMouseEnter,
      onMouseLeave,
      onFocus: onMouseEnter,
      onBlur: onMouseLeave,
    }
  }

  render() {
    return (
      <div className={`${this.props.className} u-flex-column`}>
        <div className="u-flex-row u-flex-justify">
          <button
            {...this.getNormalItemProps()}
            className="u-flex-grow-1 u-flex-column locations-list__item-button locations-list__item-button--top-left"
          >
            {this.renderMainInfo()}
          </button>
          <div className="u-flex-column u-flex-justify u-flex-dont-shrink">
            <button
              {...this.getNormalItemProps()}
              className="u-flex-grow-1 u-flex-column u-flex-end locations-list__item-button locations-list__item-button--top-right"
              tabIndex="-1"
            >
              {this.renderOpenStatus()}
            </button>
          </div>
        </div>
        <WatchlistButton
          className="locations-list__item-button locations-list__item-button--watchlist"
          location={this.props.item}
        />
        <button
          {...this.getNormalItemProps()}
          className="u-flex-row u-flex-wrap locations-list__item-facilities locations-list__item-button locations-list__item-button--bottom"
          tabIndex="-1"
        >
          {this.renderFacilities()}
        </button>
      </div>
    )
  }
}
export { LocationsListItem as UnwrappedLocationsListItem }
export default withI18n(LocationsListItem)
