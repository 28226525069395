import React from "react"
import { translate } from "@geome/intl"
import PropTypes from "prop-types"
import { BigDirectionsIcon } from "../../assets/images"

const IMG_SIZE = 67

class DirectionsButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  }

  render() {
    return (
      <button className="directions-button clean-button" onClick={this.props.onClick}>
        <img width={IMG_SIZE} height={IMG_SIZE} src={BigDirectionsIcon} />
        <div className="directions-button__text">{translate("info_window.directions.button")}</div>
      </button>
    )
  }
}

export default DirectionsButton
