export default {
  autoload: true,
  travelMode: "driving",
  avoidTolls: false,
  avoidHighways: false,
  showStationsOnRoute: true,
  corridorRadius: 5,
  localised: {
    en_GB: {
      // Would prefer to use gApi.maps.UnitSystem.IMPERIAL --
      // maybe extract SDK lib/map/optionsParser?
      unitSystem: 1
    }
  }
}
