import React from "react"
import PropTypes from "prop-types"

class OpenStatus extends React.Component {
  static propTypes = {
    openStatus: PropTypes.string.isRequired,
    translate: PropTypes.func
  }

  getClassName() {
    const { openStatus } = this.props
    const isClosed = !["open", "twenty_four_hour"].includes(openStatus)
    return ["open-status", isClosed && "open-status--closed"].filter(Boolean).join(" ")
  }

  renderIcon() {
    return (
      <svg className="open-status__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M8.907.907a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm4.666 9.333H8.24v-6h1.333v4.667h4v1.333z"
        />
      </svg>
    )
  }

  renderText() {
    const { translate, openStatus } = this.props
    return <span className="open-status__text">{translate(`opening_hours.${openStatus}`)}</span>
  }

  render() {
    if (this.props?.openStatus === "unknown") return null
    return (
      <div className={this.getClassName()}>
        {this.renderIcon()} {this.renderText()}
      </div>
    )
  }
}

export default OpenStatus
