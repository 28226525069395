const MAP_OPTIONS_DESKTOP = {
  zoomControlOptions: {
    position: "$ControlPosition.RIGHT_TOP"
  },
  streetViewControlOptions: {
    position: "$ControlPosition.RIGHT_TOP"
  },
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false
}

const MAP_OPTIONS_MOBILE = {
  ...MAP_OPTIONS_DESKTOP,
  zoomControl: false
}

export { MAP_OPTIONS_DESKTOP, MAP_OPTIONS_MOBILE }
