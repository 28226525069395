import { Command } from "@geome/flux"

class DeleteRoute extends Command {
  constructor(options) {
    super(...arguments)
    this.httpClient = options.httpClient
  }

  execute(id) {
    this.httpClient.request("DELETE", `/routes/${id}`).then(() =>
      this.dispatch({
        type: this.actionTypes.DIRECTIONS_SAVED_ROUTE_DELETED,
        payload: id,
      })
    )
  }
}

export default DeleteRoute
