import React from "react"
import PropTypes from "prop-types"
import AralGeolocationButtonView from "../../../aralGeolocationButton/view"
import SearchBoxView from "../../../searchBox/view"
import RemoveButton from "./removeButton"
import WaypointDivider from "./waypointDivider"

class InputWrapper extends React.Component {
  static propTypes = {
    wrapperProps: PropTypes.object,
    searchBoxProps: PropTypes.object,
    isLast: PropTypes.bool,
    isRemovable: PropTypes.bool,
    index: PropTypes.number,
    waypoint: PropTypes.object,
    geolocationStatus: PropTypes.string,
    isGeolocationAvailable: PropTypes.bool,
    waypointsLimitReached: PropTypes.bool,
    onGeolocationSelect: PropTypes.func,
    onWaypointRemove: PropTypes.func,
    onWaypointAdd: PropTypes.func,
    onSwitchWaypoints: PropTypes.func
  }

  constructor() {
    super(...arguments)
    this.state = {
      ...this.state
    }
  }

  componentDidUpdate() {
    if (this.props.geolocationStatus !== "busy" && this.state.currentlyGeolocatingWaypoint) {
      this.setState({ currentlyGeolocatingWaypoint: null })
    }
  }

  handleGeolocationStart(waypointName) {
    this.setState({ currentlyGeolocatingWaypoint: waypointName })
    this.props.onGeolocationSelect(waypointName)
  }

  waypointGeolocationStatus(waypointName, geolocationStatus) {
    if (this.state.currentlyGeolocatingWaypoint === waypointName) return geolocationStatus
    return null
  }

  getWaypointName(index, isLast, waypointName) {
    if (index === 0) return "origin"
    if (isLast) return "destination"
    return "waypointName"
  }

  render() {
    const {
      waypoint,
      wrapperProps,
      searchBoxProps,
      isGeolocationAvailable,
      geolocationStatus,
      onWaypointRemove,
      index,
      isRemovable,
      isLast,
      waypointsLimitReached,
      onWaypointAdd,
      onSwitchWaypoints
    } = this.props

    return (
      <div {...wrapperProps}>
        <SearchBoxView
          {...searchBoxProps}
          contextName={waypoint.name}
          useDirectionsIcon={false}
          useSearchIcon={false}
          renderClearButton={() => null}
          renderToLeft={() => (
            <AralGeolocationButtonView
              className={`directions__waypoint--${this.getWaypointName(
                index,
                isLast,
                waypoint.name
              )}`}
              isGeolocationAvailable={isGeolocationAvailable}
              geolocationStatus={this.waypointGeolocationStatus(waypoint.name, geolocationStatus)}
              onClick={() => this.handleGeolocationStart(waypoint.name)}
              geolocationContext={waypoint.name}
            />
          )}
          renderToRight={() => (
            <RemoveButton
              onClick={() => onWaypointRemove(waypoint.name)}
              index={index}
              isRemovable={isRemovable}
              waypointName={waypoint.name}
            />
          )}
        />
        {!isLast && (
          <WaypointDivider
            index={index}
            name={waypoint.name}
            waypointsLimitReached={waypointsLimitReached}
            onWaypointAdd={onWaypointAdd}
            onSwitchWaypoints={onSwitchWaypoints}
          />
        )}
      </div>
    )
  }
}

export default InputWrapper
